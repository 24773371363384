import { Button, IconButton, ImageListItem, ListSubheader, Menu, MenuItem, Select, TextField, hexToRgb } from "@mui/material";
import { AddableImages, DeleteBtn, verifyAndUpload } from "./ImageUploads";
import { req, uploadImage } from "../utils/ServerUtils";
import { useEffect, useRef, useState } from "react";
import { ColorCircle } from "./BackgroundColorPanel";
import { useSVG } from "../SVGContext";
import EditIcon from "@mui/icons-material/Edit";
import YesIcon from "@mui/icons-material/Check";
import NoIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MySketchPicker from "../MySketchPicker";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { PALETTES } from "../values/Palettes";
import LazyPalettes from "./LazyPalettes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { rand } from "../utils";
import { ROOT_GROUP_ID } from "../values/constants";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";

export function BrandPanel() {
  const { brand, setBrand } = useSVG();
  const [setupBrandKit, setSetupBrandKit] = useState(false);
  const [mode, setMode] = useState("view");

  useEffect(() => {
    setSetupBrandKit(brand && brand.colors.length == 0 && brand.texts.filter((t) => t.text?.length > 0).length == 0);
  }, [brand]);

  if (!brand) {
    return null;
  }
  function updateBrandServer(newBrand) {
    setBrand(newBrand);
    req("/brands", "PUT", JSON.stringify(newBrand)).then((res) => {
      console.log("updated brand on server");
      setBrand(res.brand);
    });
  }

  if (setupBrandKit) {
    return (
      <div>
        <Button
          variant="contained"
          fullWidth
          size="large"
          style={{
            textTransform: "none",
            background: "purple",
            color: "white",
          }}
          onClick={() => {
            setSetupBrandKit(false);
          }}
        >
          Setup Brand Kit
        </Button>
      </div>
    );
  }

  const props = {
    brand,
    setBrand,
    updateBrandServer,
    mode,
    setMode,
  };

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      {mode.startsWith("edit") && (
        <>
          <Back />
        </>
      )}
      {["edit-logo", "view"].includes(mode) && <BrandLogos {...props} />}
      {["edit-colors", "view"].includes(mode) && <BrandColors {...props} />}
      {["edit-texts", "view"].includes(mode) && <BrandTexts {...props} />}
    </div>
  );

  function Back() {
    return (
      <div
        style={{
          cursor: "pointer",
          fontSize: "0.8rem",

          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          gap: 5,
        }}
        onClick={() => {
          setMode("view");
        }}
      >
        <ArrowBackIcon
          style={{
            fontSize: "1rem",
          }}
        />
        Brand Kit
      </div>
    );
  }
}

function BrandLogos({ brand, setBrand, updateBrandServer, mode, setMode }) {
  function deleteLogo(logo) {
    const newBrand = { ...brand };
    newBrand.logos = newBrand.logos.filter((l) => l.src != logo.src);
    updateBrandServer(newBrand);
  }

  return (
    <div>
      <BrandLogoUpload brand={brand} setBrand={setBrand} updateBrandServer={updateBrandServer} mode={mode} setMode={setMode} />
      <AddableImages images={brand.logos} reload={() => {}} delImage={deleteLogo} showDelete={mode == "edit-logo"} />
    </div>
  );
}

function BrandLogo({ logo }) {
  const w = 50;
  const h = (w * parseFloat(logo.height)) / parseFloat(logo.width);
  return (
    <div>
      <img src={logo.src} width={w} height={h} />
    </div>
  );
}

function BrandLogoUpload({ brand, setBrand, updateBrandServer, mode, setMode }) {
  const inputRef = useRef();
  const [error, setError] = useState(null);

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <SectionTitle title="Logo" showEdit={mode == "view"} setMode={setMode} editMode="edit-logo" />
      {mode == "edit-logo" && (
        <>
          <Button
            type="contained"
            onClick={() => {
              inputRef.current.click();
            }}
            style={{
              width: "100%",
              background: "purple",
              textTransform: "none",
            }}
          >
            + Upload Image
          </Button>

          <input
            ref={inputRef}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              // setFullscreenLoading(true);
              verifyAndUpload(e.target.files[0], function (formData) {
                return req("/brand_image", "POST", formData, false);
              })
                .then((res) => {
                  // clear the input
                  e.target.value = "";
                  // setFullscreenLoading(false);

                  setBrand(res.brand);
                })
                .catch((err) => {
                  console.error(err);
                  e.target.value = "";
                  // setFullscreenLoading(false);
                  setError(err);
                  setTimeout(() => {
                    setError(null);
                  }, 10000);
                });
            }}
          />
        </>
      )}
    </div>
  );
}

export function toRgb(c) {
  return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
}

function BrandColors({ brand, updateBrandServer, mode, setMode }) {
  const { applyRandomizedColors } = useSVG();

  function deleteColor(i, j) {
    const newBrand = { ...brand };
    newBrand.colors[i].colors.splice(j, 1);
    updateBrandServer(newBrand);
  }

  function shuffleColors(i) {
    applyRandomizedColors(brand.colors[i].colors.map((c) => toRgb(c)));
  }
  function randomize() {
    const randomHue = Math.floor(Math.random() * 360);
    const randomColor = `hsl(${randomHue}, 100%, 50%)`;
    applyRandomizedColors([randomColor]);
  }

  return (
    <div>
      <SectionTitle title="Colors" showEdit={mode == "view"} setMode={setMode} editMode="edit-colors" />
      <div>
        <Button variant="contained" fullWidth style={{
          textTransform: "none",
          // background: "purple",
          // color: "white",
        }} onClick={randomize}>Randomize</Button>
      </div>
      <div>
        {brand.colors.map((palette, i) => (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <EditablePaletteName brand={brand} updateBrandServer={updateBrandServer} i={i} mode={mode} />

              <DeletePaletteSettings brand={brand} updateBrandServer={updateBrandServer} i={i} mode={mode} />
            </div>
            <div
              style={
                mode == "edit-colors"
                  ? {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "stretch",
                      gap: 10,
                      flexWrap: "wrap",
                    }
                  : {}
              }
            >
              {mode == "view" && (
                <div
                  className="palette-holder"
                  style={{
                    position: "relative",
                    width: 220,
                    height: 50,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "stretch",
                  }}
                >
                  {palette.colors.map((color, j) => (
                    <div
                      key={j}
                      style={{
                        // position: "absolute",
                        backgroundColor: toRgb(color),
                        width: 220 / palette.colors.length,
                        height: 50,
                        borderTopLeftRadius: j == 0 ? 5 : 0,
                        borderBottomLeftRadius: j == 0 ? 5 : 0,
                        borderTopRightRadius: j == palette.colors.length - 1 ? 5 : 0,
                        borderBottomRightRadius: j == palette.colors.length - 1 ? 5 : 0,
                        cursor: "pointer",
                      }}
                    />
                  ))}
                  <Button
                    className="palette-btn"
                    style={{
                      textTransform: "none",
                      position: "absolute",
                      display: "none",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() => shuffleColors(i)}
                  >
                    <ShuffleIcon /> Shuffle
                  </Button>
                </div>
              )}

              {mode == "edit-colors" &&
                palette.colors.map((color, j) => (
                  <ClosableColorCircle
                    key={color}
                    mode={mode}
                    background={toRgb(color)}
                    onClose={() => {
                      deleteColor(i, j);
                    }}
                  />
                ))}
              {mode == "edit-colors" && <AddColorBtn brand={brand} updateBrandServer={updateBrandServer} i={i} />}
            </div>
          </div>
        ))}
        {(mode == "edit-colors" || brand.colors.length == 0) && <AddPaletteBtn brand={brand} updateBrandServer={updateBrandServer} />}
      </div>
    </div>
  );
}
function AddPaletteBtn({ brand, updateBrandServer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Btn
        onClick={(e) => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        title="Add Palette"
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{
          padding: 20,
        }}
      >
        <LazyPalettes
          onPaletteSelect={(palette) => {
            const newBrand = { ...brand };
            palette = palette.map((c) => hexToRgbArray(c));
            newBrand.colors.push({ name: "New Palette", colors: palette });
            updateBrandServer(newBrand);
            setAnchorEl(null);
          }}
        />
      </Menu>
    </div>
  );
}
function hexToRgbArray(hexStr) {
  const hex = hexToRgb(hexStr);
  return [
    ...hex
      .replace("rgb(", "")
      .replace(")", "")
      .split(",")
      .map((c) => parseInt(c)),
    1,
  ];
}

function ClosableColorCircle({ mode, background, onClose }) {
  return (
    <div
      className="close-wrapper"
      style={{
        position: "relative",
      }}
    >
      <ColorCircle background={background} />
      {mode == "edit-colors" && (
        <CancelIcon
          className="close-icon"
          style={{
            position: "absolute",
            top: -13,
            right: -13,
            cursor: "pointer",
            display: "none",
          }}
          onClick={onClose}
        />
      )}
    </div>
  );
}
function DeletePaletteSettings({ brand, updateBrandServer, i, mode }) {
  if (mode != "edit-colors") return;
  const [anchorEl, setAnchorEl] = useState(null);

  function duplicate() {
    const newBrand = { ...brand };
    newBrand.colors.push(JSON.parse(JSON.stringify(newBrand.colors[i])));
    updateBrandServer(newBrand);
    setAnchorEl(null);
  }
  function deletePal() {
    const newBrand = { ...brand };
    newBrand.colors.splice(i, 1);
    updateBrandServer(newBrand);
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={() => {}}>
        <MoreHorizIcon
          onClick={(e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={duplicate}>Duplicate Palettte</MenuItem>
        <MenuItem onClick={deletePal}>Delete Palettte</MenuItem>
      </Menu>
    </>
  );
}
function EditablePaletteName({ brand, updateBrandServer, i, mode }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(brand.colors[i].name);

  function confirm() {
    const newBrand = { ...brand };
    newBrand.colors[i].name = name;
    updateBrandServer(newBrand);
  }

  return (
    <>
      {isEditing && (
        <TextField
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={() => {
            confirm();
            setIsEditing(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Escape") {
              if (e.key === "Enter") {
                confirm();
              }
              setIsEditing(false);
              e.target.blur();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      )}
      {!isEditing && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            margin: "10px 0px 10px 0px",
            gap: 10,
            maxWidth: mode == "edit-colors" ? "80%" : "100%", // to give space for the dots icon
          }}
        >
          <div
            className="single-line"
            style={{
              overflow: "hidden",
            }}
          >
            {brand.colors[i].name}
          </div>
          {mode == "edit-colors" && (
            <IconButton
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <EditIcon
                style={{
                  fontSize: "1rem",
                }}
              />
            </IconButton>
          )}
        </div>
      )}
    </>
  );
}
function AddColorBtn({ brand, updateBrandServer, i }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function onColorSelect(clr) {
    const c = [clr.rgb.r, clr.rgb.g, clr.rgb.b, clr.rgb.a];
    const newBrand = { ...brand };
    newBrand.colors[i].colors.push(c);
    updateBrandServer(newBrand);
    setAnchorEl(null);
  }
  return (
    <div
      style={{
        color: "black",
      }}
    >
      <ColorCircle
        background={"white"}
        onClick={(e) => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        icon=<AddIcon />
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{
          padding: 20,
        }}
      >
        <MySketchPicker onChangeComplete={onColorSelect} color="#ccc" setShowPicker={setAnchorEl} />
      </Menu>
    </div>
  );
}
const sectionTitleStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "30px 0 10px 0",
};

function SectionTitle({ title, showEdit, setMode, editMode }) {
  return (
    <div style={sectionTitleStyle}>
      <div
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </div>

      {showEdit && (
        <div
          style={{
            fontWeight: "bold",
            fontsize: "1.2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setMode(editMode);
          }}
        >
          Edit
        </div>
      )}
    </div>
  );
}

function BrandTexts({ brand, updateBrandServer, mode, setMode }) {
  const [editingText, setEditingText] = useState(null);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    setShowBtn(mode == "view" && !brand.texts.find((t) => t.text?.length > 0));
  }, [brand, mode]);

  return (
    <div>
      <SectionTitle title="Fonts" showEdit={mode == "view" && brand.texts.find((t) => t.text?.length > 0)} setMode={setMode} editMode="edit-texts" />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          flexDirection: "column",
          alignItems: "stretch",
          gap: 15,
          color: "white",
        }}
      >
        {showBtn && <Btn onClick={() => setMode("edit-texts")} title="Add Brand Fonts" />}
        {brand.texts.map(
          (text, i) =>
            ((mode == "view" && text.text?.length > 0) || mode == "edit-texts") && (
              <>
                {i == editingText && <TextEditingBox setEditingText={setEditingText} updateBrandServer={updateBrandServer} brand={brand} i={i} mode={mode} />}
                {i != editingText && <TextShowingBox setEditingText={setEditingText} brand={brand} i={i} mode={mode} />}
              </>
            ),
        )}
      </div>
    </div>
  );
}
function TextShowingBox({ brand, setEditingText, i, mode }) {
  const { addItems } = useSVG();

  function addText(text) {
    const node = {
      content: [
        {
          attrs: {
            fontSize: text.size + "px",
          },
          content: [
            {
              marks: [
                {
                  attrs: {
                    color: "#000000",
                    fontFamily: text.font,
                    fontSize: text.size + "px",
                  },
                  type: "textStyle",
                },
              ],
              text: text.text,
              type: "text",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    };
    if (text.bold) {
      node.content[0].content[0].marks.push({
        type: "bold",
      });
    }
    if (text.italic) {
      node.content[0].content[0].marks.push({
        type: "italic",
      });
    }
    // calculate width based on text length
    const width = text.text.length * text.size * 0.6;
    addItems([
      {
        id: "text-" + rand(),
        x: 50,
        y: 50,
        width,
        height: 100,
        group: ROOT_GROUP_ID,
        type: "text",
        proseMirrorData: node,
        html: generateHTML(node, EditorExtensions),
      },
    ]);
  }

  return (
    <div
      key={i}
      style={{
        fontFamily: brand.texts[i].font,
        // fontSize: brand.texts[i].size,
        fontWeight: brand.texts[i].bold ? "bold" : "normal",
        fontStyle: brand.texts[i].italic ? "italic" : "normal",
        padding: 10,
        background: "#333",
        borderRadius: 5,

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        if (mode == "view") {
          addText(brand.texts[i]);
        }
      }}
    >
      <div>{brand.texts[i].text || brand.texts[i].defaultText}</div>
      {mode == "edit-texts" && (
        <IconButton
          onClick={() => {
            setEditingText(i);
          }}
        >
          <EditIcon
            style={{
              color: "black",
            }}
          />
        </IconButton>
      )}
    </div>
  );
}
const fontSizes = [6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88, 96, 104, 120, 144];

function TextEditingBox({ brand, updateBrandServer, i, setEditingText }) {
  const [font, setFont] = useState(brand.texts[i].font);
  const [text, setText] = useState(brand.texts[i].text || brand.texts[i].defaultText);
  const [size, setSize] = useState(brand.texts[i].size);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);

  useEffect(() => {
    setFont(brand.texts[i].font);
    setText(brand.texts[i].text || brand.texts[i].defaultText);
  }, [brand]);

  function onChange(e) {
    setText(e.target.value);
  }
  function onFontSelect(font) {
    setFont(font);
  }
  function confirm() {
    const newBrand = { ...brand };
    newBrand.texts[i].font = font;
    newBrand.texts[i].text = text;
    newBrand.texts[i].size = size;
    newBrand.texts[i].bold = bold;
    newBrand.texts[i].italic = italic;
    updateBrandServer(newBrand);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        background: "lightgray",
        justifyContent: "start",
        alignItems: "stretch",
        color: "black",
        background: "#333",
      }}
    >
      <FontsSelect onFontSelect={onFontSelect} font={font} />
      <TextField label="Text" value={text} onChange={onChange} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "stretch",
          gap: 10,
        }}
      >
        <Select
          value={size}
          onChange={(e) => {
            setSize(e.target.value);
          }}
        >
          {fontSizes.map((size) => (
            <MenuItem value={size}>{size}</MenuItem>
          ))}
        </Select>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setBold(!bold);
          }}
          style={{
            background: bold ? "#222" : "transparent",
            borderColor: bold ? "black" : "white",
          }}
        >
          B
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setItalic(!italic);
          }}
          style={{
            background: italic ? "#222" : "transparent",
            borderColor: italic ? "black" : "white",
          }}
        >
          I
        </Button>
      </div>
      <div>
        <div
          style={{
            fontFamily: font,
            // fontSize: brand.texts[i].size,
            padding: 10,
            border: "solid 2px white",
            borderRadius: 5,
            color: "white",
            fontWeight: bold ? "bold" : "normal",
            fontStyle: italic ? "italic" : "normal",
          }}
        >
          {text}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: 10,
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => {
            confirm();
            setEditingText(-1);
          }}
        >
          <YesIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setEditingText(-1);
          }}
        >
          <NoIcon />
        </IconButton>
      </div>
    </div>
  );
}
function FontsSelect({ onFontSelect, font }) {
  const { fonts } = useSVG();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(fonts.findIndex((f) => f === font));
  }, [font]);

  return (
    <Select
      size="small"
      id="select"
      value={index}
      onChange={(e, v) => {
        onFontSelect(fonts[e.target.value]);
      }}
      sx={{ padding: "2px 4px" }}
    >
      {fonts.map((font, idx) => {
        if (font.type === "divider") {
          return <ListSubheader sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.1rem" }}>{font}</ListSubheader>;
        }
        return (
          <MenuItem key={font} value={idx}>
            <div style={{ justifyContent: "space-between", width: "100%", fontFamily: font }}>{font}</div>
          </MenuItem>
        );
      })}
    </Select>
  );
}

function Btn(props) {
  return (
    <Button
      style={{
        textTransform: "none",
      }}
      variant="contained"
      fullWidth
      {...props}
    >
      {props.title}
    </Button>
  );
}
