import { Button, IconButton } from "@mui/material";
import { useSVG } from "./SVGContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateDesignServer } from "./utils/ServerUtils";

export function Pages({}) {
  const { design, clonePage } = useSVG();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 15,
        justifyContent: "start",
        alignItems: "center",
        padding: 15,
        marginTop: 20,
        border: "solid 1px #ccc",

        overflowY: "scroll",
      }}
    >
      {design.pages.map((page, index) => (
        <Page pageIdx={index} key={index} page={page} />
      ))}
      <Button variant="outlined" style={{
        textTransform: "none",
        width: "100%",
      }} onClick={clonePage}>+ Add Page</Button>

    </div>
  );
}
function Page({ page, pageIdx }) {
  const { setSelectedPage, width, height, design, selectedPage, setDesign } = useSVG();

  const aspectRatio = width / height;
  const w = 200;
  const h = w / aspectRatio;

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
      width: "100%",
    }}>
      <Button variant="outlined" style={{
        textTransform: "none",
        backgroundColor: selectedPage === pageIdx ? "purple" : "#ddd",
        color: selectedPage === pageIdx ? "white" : "black",
      }} onClick={() => setSelectedPage(pageIdx)}>Page {pageIdx + 1}</Button>
      <IconButton onClick={(e) => {
          design.pages.splice(selectedPage, 1);
          setDesign({ ...design });
          setSelectedPage(Math.min(selectedPage, design.pages.length - 1));

          updateDesignServer(design).then((res) => {
            setDesign({ ...design, version: res.version });
          });
      }}>
        <DeleteIcon
          style={{
            color: "#bb1818",
          }}
        />
      </IconButton>
    </div>
  )

  return (
    <div
      style={{
        border: "solid 1px #ccc",
        borderRadius: 5,
        padding: 5,
      }}
      onClick={() => setSelectedPage(pageIdx)}
    >
      <div
        style={{
          width: w + "px",
          height: h + "px",
        }}
        className="pages-svg-container"
      >
        <img src={page.preview} style={{ width: "100%", height: "100%" }} />
      </div>
    </div>
  );
}
