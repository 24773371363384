// @ts-nocheck

import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import { Cursor } from "./values/enums";
import { createMultiSelectBox, getUnmovingPoint } from "./utils/PointerUtils";
import { getTransformStr } from "./utils/transformUtils";
import { getCoordsInSvg, getSVGBoundingBox } from "./utils/utils";
import { FRAME_DEFS } from "./values/constants";
import { useScaler } from "./useScaler";

export function MultiselectBox({ multiSelectBox }) {
  const CIRCLERADIUS = 10,
    RECTWIDTH = 40,
    RECTHEIGHT = 12,
    RECTCORNER = 10;

  const z = useSVG();
  const { setCursor, viewBox, items, update, setTool, croppingItemId, selectedIds, getBox, selectedPoint, setSelectedPoint, svgRef, width, height } = useSVG();
  let { tool } = useSVG();

  const scaler = useScaler({ update });
  let item = items[selectedIds[0]];

  let [scale, setScale] = useState(1);

  useEffect(() => {
    scale = viewBox.width / width;
    setScale(scale);
  }, [viewBox, multiSelectBox]);

  if (!multiSelectBox) return null;

  return (
    <g id="mult-select-box">
      <rect id="multi" x={multiSelectBox.x} y={multiSelectBox.y} width={multiSelectBox.width} height={multiSelectBox.height} fill="none" stroke="blue" strokeWidth={2} />

      {multiSelectBox.points.map((p, i) => (
        <Point key={i} p={p} scale={scale} setCursor={setCursor} />
      ))}
    </g>
  );

  function Point({ p, scale }) {
    const { isMobile } = useSVG();
    let [fill, setFill] = useState("white");

    let dx = 0,
      dy = 0;
    if (p.type == "rect") {
      if (p.cursor == Cursor.ResizeN || p.cursor == Cursor.ResizeS) {
        dx = (RECTWIDTH / 2) * scale;
        dy = (RECTHEIGHT / 2) * scale;
      } else if (p.cursor == Cursor.ResizeW || p.cursor == Cursor.ResizeE) {
        dx = (RECTWIDTH / 2) * scale;
        dy = (RECTHEIGHT / 2) * scale;
      }
    }
    function triggerScaler(e, x, y) {
      if (!tool || tool.name != "scaler") {
        // Since hover is absent on touch devices, we need to set the tool on ontouchstart. For consistency, we do the same for onMouseDown
        // Then we also have to trigger the onMouseDown manually since this touch start won't cause that to trigger (tool will be set only after this e completes bubbling)

        let xy = getCoordsInSvg(svgRef, { x, y });
        scaler.onMouseDownNew({ e, xy, cursor: p.cursor, z });

        setSelectedPoint(p);

        // setCursor(p.cursor);
        setTool(scaler);

        e.stopPropagation();
        e.preventDefault();
      }
    }
    function onMouseDown(e) {
      triggerScaler(e, e.clientX, e.clientY);
    }
    function onTouchStart(e) {
      if (!tool || tool.name != "scaler") {
        // Since hover is absent on touch devices, we need to set the tool on ontouchstart (instead of on onmouseenter).
        // Then we also have to trigger the onMouseDown manually since this touch start won't cause that to trigger (tool will be set only after this e completes bubbling)
        if (e.touches && e.touches.length == 1 && e.touches[0]) {
          triggerScaler(e, e.touches[0].clientX, e.touches[0].clientY);
        }
      }
    }
    function onMouseLeave(e) {
      // note: i'm just leaving this here to remind you that this won't work. We setTool to null in onMouseUp of scaler, and if tool is null,
      // editor (a root component) will set it to pointer. Btw, this callback is not even hooked up.
      // setTool(null);
      // setCursor(Cursor.Default);
    }
    const others = {
      stroke: "blue",
      // it's 90.0001 bec otherwise you get a weird line
      transform: p.cursor == Cursor.ResizeW || p.cursor == Cursor.ResizeE ? `rotate(90.0001 ${p.x} ${p.y})` : "",
      className: "handle " + (selectedPoint?.cursor == p.cursor ? "selected" : ""),
      "data-pointer-ignore": true,
    };

    function isSelected(p) {
      if (!selectedPoint) return false;
      return Math.abs(p.x - selectedPoint.x) < 0.1 && Math.abs(p.y - selectedPoint.y) < 0.1;
    }

    return (
      <>
        {isMobile && !item?.proseMirrorData && p.type != "rect" && isSelected(p) && (
          <circle
            cx={p.x}
            cy={p.y}
            r={CIRCLERADIUS * 2 * scale}
            fill={"orange"}
            opacity={0.8}
            {...others}
            stroke="orange"
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            style={{
              pointeres: tool?.name == "scaler" ? "none" : "auto",
            }}
          />
        )}
        {p.type != "rect" && (
          <circle
            cx={p.x}
            cy={p.y}
            r={CIRCLERADIUS}
            fill={fill}
            {...others}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            style={{
              pointeres: tool?.name == "scaler" ? "none" : "auto",
            }}
          />
        )}
        {p.type == "rect" && !item?.proseMirrorData && (
          <rect
            x={p.x - dx}
            y={p.y - dy}
            width={RECTWIDTH * scale}
            height={RECTHEIGHT * scale}
            rx={RECTCORNER * scale}
            fill={fill}
            {...others}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            style={{
              pointeres: tool?.name == "scaler" ? "none" : "auto",
            }}
          />
        )}
      </>
    );
  }
}
