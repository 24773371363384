const BASE_URL = "https://api.makemybrand.ai";

export function req(path, method = "GET", bodyData, json = true) {
  const opts = {
    method,
    headers: {},
    credentials: "include",
  };
  if (window.location.hostname === "localhost") {
    opts.headers.authorization = "supersecrettoken";
  }
  if (bodyData) {
    opts.body = bodyData;
  }
  if (json) {
    opts.headers["Content-Type"] = "application/json";
  }
  const url = BASE_URL + path;
  return fetch(url, opts).then((response) => {
    return response.json().then((data) => {
      if (data.error) {
        throw new Error(data.error);
      }
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error(data.message);
      }
      return data;
    });
  });
}
export const createDesignFromTemplateServer = (templateId) => {
  return req(
    "/design_from_template",
    "POST",
    JSON.stringify({
      template_id: templateId,
    }),
  );
};
export const createDesignServer = (data) => {
  return req("/designs", "POST", JSON.stringify(data));
};

export const updateDesignServer = (design) => {
  if (design.isBlock) {
    return req(`/blocks/${design.id}`, "PUT", JSON.stringify(design));
  } else if (design.isTemplate) {
    return req(
      `/templates`,
      "PUT",
      JSON.stringify({
        ...design,
        template_id: design.id,
      }),
    );
  } else {
    return req(`/designs/${design.id}`, "PUT", JSON.stringify(design));
  }
};

export const fetchDesigns = (page) => {
  return req(`/designs?page=${page}`);
};

export const fetchDesign = (designId) => {
  return req(`/designs/${designId}`);
};

export const createBlockServer = (data) => {
  return req("/blocks", "POST", JSON.stringify(data));
};
export const fetchBlock = (id) => {
  return req(`/blocks/${id}`).then((res) => ({
    ...res,
    isBlock: true,
  }));
};

export const fetchTemplate = (id) => {
  return req(`/template?id=${id}`).then((res) => ({
    ...res,
    isTemplate: true,
  }));
};

export const fetchImages = (cb) => {
  req("/images").then(cb);
};

export const uploadImage = (formData) => {
  return req("/upload", "POST", formData, false);
};

export const deleteImage = (imageId, cb) => {
  req(`/image/${imageId}`, "DELETE").then(cb);
};

export const searchPexelsImages = (query, page = 1) => {
  // clean query
  query = query.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
  return req(`/search_images?query=${query}&page=${page}`);
};

export const searchIcons = (query, page) => {
  // clean query
  query = query.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
  return req(`/v2/search_icons?query=${query}&page=${page}`);
};

export const fetchTemplates = (category, page) => {
  return req(`/templates?category=${category}&page=${page}`);
};

export const searchTemplates = (query, category, page) => {
  let url = `/templates_search?`;
  if (category) {
    url += `&category=${category}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (query) {
    url += `&q=${query}`;
  }
  return req(url);
};

export const verifyPayment = () => {
  // TODO
};

export const fetchPurchasedTemplates = () => {
  return req("/purchased_templates");
};

export const fetchUserInfo = () => {
  return req("/user_info");
};
