const fullList = false; // window.location.href.includes("localhost");
let deselected = [
  { id: "template_18163.jpg", width: 816, height: 1950 },
  { id: "template_7983.jpg", width: 816, height: 1300 },
];
let defaultTemplates = [
	{ "id": "2568", "width": 816, "height": 1470 },
	{ "id": "2570", "width": 827, "height": 1578 },
	{ "id": "3189", "width": 816, "height": 921 },
  { "id": "3210", "width": 816, "height": 2938 },
  { "id": "5522621a-3e16-4c48-b973-caf30dad2af2-0", "width": 378, "height": 756, "preview": "https://cdn.makemybrand.ai/bfdbe7f9/0.png?v=28_1736425171"},
  { "id": "5522621a-3e16-4c48-b973-caf30dad2af2-5", "width": 378, "height": 836, "preview": "https://cdn.makemybrand.ai/cb762c20/0.png?v=13_1736432696"},
  { "id": "5522621a-3e16-4c48-b973-caf30dad2af2-1", "width": 378, "height": 506, "preview": "https://cdn.makemybrand.ai/8584ae79/0.png?v=20_1736443284"},
  // { "id:": "77af7b44-8062-43a9-ba7e-97dd03d6e0e1-0", "width": 1056, "height": 816, "preview": "https://cdn.makemybrand.ai/1cb9d3d4/0.svg?v=11"},
  {"id": "3214", "width": 816, "height": 1955, "preview": "https://cdn.makemybrand.ai/b7a9c0ac/0.png?v=1_1736517717"},
]
;

if (fullList) {
  defaultTemplates = [
    { id: "template_21208.jpg", width: 816, height: 1056 },
    { id: "template_22429.jpg", width: 816, height: 1056 },
    { id: "template_13391.jpg", width: 1056, height: 1632 },
    { id: "template_14407.jpg", width: 816, height: 3616 },
    { id: "template_5687.jpg", width: 816, height: 2050 },
    { id: "template_10199.jpg", width: 816, height: 2070 },

    { id: "template_781.jpg", width: 816, height: 2101 },
    { id: "template_16295.jpg", width: 816, height: 1344 },
    { id: "template_775.jpg", width: 816, height: 1900 },
    { id: "template_2842.jpg", width: 1056, height: 1632 },
    { id: "template_9899.jpg", width: 1056, height: 1632 },
    { id: "template_6099.jpg", width: 1056, height: 1632 },
    { id: "template_7765.jpg", width: 1056, height: 1632 },
    { id: "template_16706.jpg", width: 1056, height: 1632 },
    { id: "template_22134.jpg", width: 816, height: 2900 },
    { id: "template_23372.jpg", width: 1056, height: 816 },
    { id: "template_12105.jpg", width: 816, height: 1056 },
    { id: "template_20989.jpg", width: 816, height: 1056 },
    { id: "template_17318.jpg", width: 816, height: 4500 },
    { id: "template_7432.jpg", width: 816, height: 2550 },
    { id: "template_11615.jpg", width: 816, height: 1056 },
    { id: "template_2382.jpg", width: 816, height: 2500 },
    { id: "template_1055.jpg", width: 816, height: 1056 },
    { id: "template_4913.jpg", width: 816, height: 2629 },
    { id: "template_9541.jpg", width: 816, height: 1470 },
    { id: "template_12257.jpg", width: 816, height: 1500 },
    { id: "template_14700.jpg", width: 1300, height: 1700 },
    { id: "template_2430.jpg", width: 816, height: 2362 },
    { id: "template_18679.jpg", width: 816, height: 3349 },
    { id: "template_14214.jpg", width: 816, height: 1056 },
    { id: "template_8165.jpg", width: 816, height: 2082 },
    { id: "template_12849.jpg", width: 816, height: 1489 },
    { id: "template_31225", width: 816, height: 1056 },
    { id: "template_16660.jpg", width: 816, height: 2810 },
    { id: "template_31226", width: 816, height: 1056 },
    { id: "template_11780.jpg", width: 816, height: 2525 },
    { id: "template_3317.jpg", width: 816, height: 2143 },
    { id: "template_20109.jpg", width: 816, height: 2159 },
    { id: "template_23352.jpg", width: 816, height: 4000 },
    { id: "template_9797.jpg", width: 816, height: 1056 },
    { id: "template_8621.jpg", width: 816, height: 2341 },
    { id: "template_10839.jpg", width: 816, height: 2500 },
    { id: "template_11514.jpg", width: 816, height: 2075 },
    { id: "template_7571.jpg", width: 816, height: 2100 },
    { id: "template_13409.jpg", width: 816, height: 2040 },
    { id: "template_22180.jpg", width: 750, height: 1160 },
    { id: "template_3872.jpg", width: 816, height: 4867 },
    { id: "template_21979.jpg", width: 816, height: 2200 },
    { id: "template_11848.jpg", width: 816, height: 2464 },
    { id: "template_0.jpg", width: 816, height: 3329 },
    { id: "template_16639.jpg", width: 816, height: 2600 },
    { id: "template_21258.jpg", width: 816, height: 2400 },
    { id: "template_23116.jpg", width: 816, height: 1056 },
    { id: "template_5862.jpg", width: 560, height: 900 },
    { id: "template_21524.jpg", width: 816, height: 1883 },
    { id: "template_8858.jpg", width: 816, height: 2431 },
    { id: "template_3433.jpg", width: 816, height: 2915 },
    { id: "template_21325.jpg", width: 816, height: 1554 },
    { id: "template_16109.jpg", width: 816, height: 3650 },
    { id: "template_20216.jpg", width: 816, height: 1056 },
    { id: "template_3886.jpg", width: 816, height: 2400 },
    { id: "template_17489.jpg", width: 816, height: 2849 },
    { id: "template_7084.jpg", width: 816, height: 3560 },
    { id: "template_17785.jpg", width: 816, height: 4525 },
    { id: "template_10587.jpg", width: 816, height: 1056 },
    { id: "template_699.jpg", width: 1200, height: 1250 },
    { id: "template_23904.jpg", width: 900, height: 1800 },
    { id: "template_3100.jpg", width: 816, height: 1056 },
    { id: "template_4884.jpg", width: 816, height: 1250 },
    { id: "template_21573.jpg", width: 816, height: 1056 },
    { id: "template_5856.jpg", width: 816, height: 1056 },
    { id: "template_12640.jpg", width: 904, height: 1415 },
    { id: "template_13200.jpg", width: 816, height: 1056 },
    { id: "template_8236.jpg", width: 816, height: 2967 },
    { id: "template_14645.jpg", width: 1056, height: 816 },
    { id: "template_1581.jpg", width: 816, height: 3080 },
    { id: "template_9508.jpg", width: 900, height: 3281 },
    { id: "template_5553.jpg", width: 816, height: 1056 },
    { id: "template_9707.jpg", width: 1056, height: 816 },
    { id: "template_9604.jpg", width: 816, height: 1056 },
    { id: "template_6371.jpg", width: 816, height: 2551 },
    { id: "template_14646.jpg", width: 816, height: 1515 },
    { id: "template_11255.jpg", width: 816, height: 4393 },
    { id: "template_17718.jpg", width: 900, height: 2800 },
    { id: "template_89.jpg", width: 816, height: 3050 },
    { id: "template_1225.jpg", width: 816, height: 2620 },
    { id: "template_12737.jpg", width: 816, height: 1800 },
    { id: "template_12007.jpg", width: 816, height: 1056 },
    { id: "template_5891.jpg", width: 1056, height: 1632 },
    { id: "template_11717.jpg", width: 816, height: 3950 },
    { id: "template_19245.jpg", width: 816, height: 3450 },
    { id: "template_8970.jpg", width: 816, height: 1056 },
    { id: "template_14421.jpg", width: 816, height: 1056 },
    { id: "template_7395.jpg", width: 1056, height: 816 },
    { id: "template_12769.jpg", width: 1056, height: 816 },
    { id: "template_20801.jpg", width: 1056, height: 816 },
    { id: "template_16059.jpg", width: 816, height: 1056 },
    { id: "template_13675.jpg", width: 816, height: 1900 },
    { id: "template_22609.jpg", width: 816, height: 1750 },
    { id: "template_17475.jpg", width: 816, height: 3150 },
    { id: "template_16066.jpg", width: 816, height: 1100 },
    { id: "template_19290.jpg", width: 816, height: 2520 },
    { id: "template_20278.jpg", width: 816, height: 6178 },
    { id: "template_18213.jpg", width: 816, height: 1800 },
    { id: "template_14668.jpg", width: 850, height: 5287 },
    { id: "template_20686.jpg", width: 816, height: 2101 },
    { id: "template_13572.jpg", width: 816, height: 1056 },
    { id: "template_19452.jpg", width: 816, height: 3300 },
    { id: "template_8791.jpg", width: 816, height: 2000 },
    { id: "template_18676.jpg", width: 816, height: 3600 },
    { id: "template_5118.jpg", width: 816, height: 4206 },
    { id: "template_6189.jpg", width: 816, height: 2171 },
    { id: "template_1729.jpg", width: 816, height: 4000 },
    { id: "template_3422.jpg", width: 816, height: 2350 },
    { id: "template_17836.jpg", width: 816, height: 2250 },
    { id: "template_23008.jpg", width: 816, height: 1056 },
    { id: "template_15407.jpg", width: 816, height: 1772 },
    { id: "template_3985.jpg", width: 640, height: 1500 },
    { id: "template_12906.jpg", width: 816, height: 1056 },
    { id: "template_18589.jpg", width: 816, height: 2200 },
    { id: "template_5585.jpg", width: 816, height: 1700 },
    { id: "template_6016.jpg", width: 816, height: 2620 },
    { id: "template_22809.jpg", width: 816, height: 1056 },
    { id: "template_20444.jpg", width: 816, height: 1057 },
    { id: "template_15603.jpg", width: 816, height: 1500 },
    { id: "template_9154.jpg", width: 816, height: 1060 },
    { id: "template_20199.jpg", width: 816, height: 1056 },
    { id: "template_7170.jpg", width: 816, height: 3320 },
    { id: "template_2767.jpg", width: 816, height: 1056 },
    { id: "template_22523.jpg", width: 816, height: 3108 },
    { id: "template_19744.jpg", width: 816, height: 2826 },
    { id: "template_10778.jpg", width: 816, height: 3110 },
    { id: "template_5779.jpg", width: 816, height: 1980 },
    { id: "template_11289.jpg", width: 620, height: 2640 },
    { id: "template_2169.jpg", width: 816, height: 1056 },
    { id: "template_13788.jpg", width: 816, height: 2280 },
    { id: "template_23142.jpg", width: 816, height: 1056 },
    { id: "template_17216.jpg", width: 750, height: 1160 },
    { id: "template_3935.jpg", width: 816, height: 1056 },
    { id: "template_16907.jpg", width: 1500, height: 1350 },
    { id: "template_21483.jpg", width: 816, height: 1056 },
    { id: "template_379.jpg", width: 816, height: 1056 },
    { id: "template_20907.jpg", width: 816, height: 1056 },
    { id: "template_23712.jpg", width: 799, height: 3722 },
    { id: "template_18664.jpg", width: 816, height: 1950 },
    { id: "template_451.jpg", width: 816, height: 2625 },
    { id: "template_19748.jpg", width: 816, height: 1056 },
    { id: "template_5511.jpg", width: 816, height: 1056 },
    { id: "template_20929.jpg", width: 816, height: 2450 },
    { id: "template_9958.jpg", width: 816, height: 1850 },
    { id: "template_12188.jpg", width: 816, height: 1056 },
    { id: "template_13487.jpg", width: 816, height: 2180 },
    { id: "template_12373.jpg", width: 830, height: 1645 },
    { id: "template_13555.jpg", width: 816, height: 1685 },
    { id: "template_5226.jpg", width: 665, height: 2172 },
    { id: "template_13889.jpg", width: 816, height: 1056 },
    { id: "template_20221.jpg", width: 620, height: 1850 },
    { id: "template_21537.jpg", width: 620, height: 2000 },
    { id: "template_4618.jpg", width: 1632, height: 1056 },
    { id: "template_9543.jpg", width: 816, height: 1056 },
    { id: "template_2495.jpg", width: 816, height: 1056 },
    { id: "template_5738.jpg", width: 1300, height: 950 },
    { id: "template_13595.jpg", width: 816, height: 1056 },
    { id: "template_5060.jpg", width: 816, height: 1521 },
    { id: "template_5965.jpg", width: 900, height: 6250 },
    { id: "template_9547.jpg", width: 793, height: 4177 },
    { id: "template_12382.jpg", width: 816, height: 2007 },
    { id: "template_292.jpg", width: 794, height: 1360 },
    { id: "template_8193.jpg", width: 816, height: 3340 },
    { id: "template_12197.jpg", width: 816, height: 2262 },
    { id: "template_139.jpg", width: 816, height: 1056 },
    { id: "template_7678.jpg", width: 816, height: 2515 },
    { id: "template_23556.jpg", width: 640, height: 6800 },
    { id: "template_20416.jpg", width: 816, height: 3906 },
    { id: "template_19711.jpg", width: 816, height: 1344 },
    { id: "template_20484.jpg", width: 816, height: 1200 },
    { id: "template_1957.jpg", width: 816, height: 1250 },
    { id: "template_16730.jpg", width: 816, height: 1056 },
    { id: "template_12494.jpg", width: 816, height: 1850 },
    { id: "template_1746.jpg", width: 880, height: 980 },
    { id: "template_20857.jpg", width: 816, height: 1700 },
    { id: "template_12540.jpg", width: 816, height: 4000 },
    { id: "template_31229", width: 816, height: 1344 },
    { id: "template_18147.jpg", width: 793, height: 3600 },
    { id: "template_1251.jpg", width: 816, height: 3000 },
    { id: "template_20796.jpg", width: 620, height: 2526 },
    { id: "template_9055.jpg", width: 816, height: 4189 },
    { id: "template_22942.jpg", width: 816, height: 2152 },
    { id: "template_5535.jpg", width: 816, height: 1056 },
    { id: "template_11619.jpg", width: 816, height: 1056 },
    { id: "template_7103.jpg", width: 816, height: 1873 },
    { id: "template_17081.jpg", width: 816, height: 2620 },
    { id: "template_12463.jpg", width: 816, height: 6056 },
    { id: "template_22758.jpg", width: 816, height: 7956 },
    { id: "template_16929.jpg", width: 816, height: 1900 },
    { id: "template_2794.jpg", width: 794, height: 1360 },
    { id: "template_1841.jpg", width: 816, height: 1506 },
    { id: "template_17566.jpg", width: 794, height: 1360 },
    { id: "template_2660.jpg", width: 816, height: 1056 },
    { id: "template_31230", width: 1056, height: 816 },
    { id: "template_31237", width: 816, height: 1056 },
    { id: "template_31238", width: 816, height: 1056 },
    { id: "template_31241", width: 1056, height: 816 },
    { id: "template_31245", width: 816, height: 1056 },
    { id: "template_31246", width: 816, height: 1056 },
    { id: "template_31247", width: 1056, height: 816 },
    { id: "template_31248", width: 816, height: 1056 },
    { id: "template_31251", width: 816, height: 1056 },
    { id: "template_31253", width: 816, height: 1056 },
    { id: "template_21009.jpg", width: 816, height: 3200 },
    { id: "template_15720.jpg", width: 816, height: 1056 },
    { id: "template_18265.jpg", width: 816, height: 3000 },
    { id: "template_20356.jpg", width: 816, height: 1056 },
    { id: "template_15867.jpg", width: 816, height: 1663 },
    { id: "template_5631.jpg", width: 700, height: 4615 },
    { id: "template_23699.jpg", width: 860, height: 1627 },
    { id: "template_21143.jpg", width: 816, height: 3295 },
    { id: "template_20619.jpg", width: 816, height: 8000 },
    { id: "template_4339.jpg", width: 816, height: 2063 },
    { id: "template_5446.jpg", width: 816, height: 2796 },
    { id: "template_22250.jpg", width: 816, height: 1870 },
    { id: "template_14487.jpg", width: 816, height: 2050 },
    { id: "template_2773.jpg", width: 816, height: 1400 },
    { id: "template_19839.jpg", width: 816, height: 1025 },
    { id: "template_14428.jpg", width: 816, height: 2100 },
    { id: "template_5521.jpg", width: 816, height: 1070 },
    { id: "template_21915.jpg", width: 816, height: 2065 },
    { id: "template_9740.jpg", width: 1344, height: 816 },
    { id: "template_4241.jpg", width: 900, height: 2800 },
    { id: "template_22086.jpg", width: 816, height: 2300 },
    { id: "template_22940.jpg", width: 816, height: 1800 },
    { id: "template_17651.jpg", width: 816, height: 1056 },
    { id: "template_10217.jpg", width: 816, height: 1650 },
    { id: "template_6199.jpg", width: 816, height: 1650 },
    { id: "template_955.jpg", width: 816, height: 1650 },
    { id: "template_17812.jpg", width: 816, height: 1650 },
    { id: "template_2555.jpg", width: 816, height: 1750 },
    { id: "template_15610.jpg", width: 816, height: 1650 },
    { id: "template_6153.jpg", width: 816, height: 1750 },
    { id: "template_8299.jpg", width: 1056, height: 816 },
    { id: "template_23722.jpg", width: 1056, height: 816 },
    { id: "template_1476.jpg", width: 1632, height: 1056 },
    { id: "template_2076.jpg", width: 816, height: 1350 },
    { id: "template_1126.jpg", width: 816, height: 2070 },
    { id: "template_14642.jpg", width: 816, height: 1150 },
    { id: "template_15675.jpg", width: 816, height: 1056 },
    { id: "template_16210.jpg", width: 816, height: 1850 },
    { id: "template_4221.jpg", width: 816, height: 3018 },
    { id: "template_8964.jpg", width: 816, height: 1550 },
    { id: "template_2708.jpg", width: 816, height: 2000 },
    { id: "template_21239.jpg", width: 816, height: 3200 },
    { id: "template_21406.jpg", width: 816, height: 1056 },
    { id: "template_8405.jpg", width: 816, height: 1600 },
    { id: "template_22845.jpg", width: 816, height: 1056 },
    { id: "template_22814.jpg", width: 816, height: 1700 },
    { id: "template_4752.jpg", width: 816, height: 2340 },
    { id: "template_80.jpg", width: 816, height: 1550 },
    { id: "template_18494.jpg", width: 816, height: 1900 },
    { id: "template_9058.jpg", width: 816, height: 1800 },
    { id: "template_10930.jpg", width: 800, height: 1850 },
    { id: "template_17665.jpg", width: 816, height: 2000 },
    { id: "template_1854.jpg", width: 816, height: 1056 },
    { id: "template_2931.jpg", width: 816, height: 1056 },
    { id: "template_12385.jpg", width: 816, height: 1450 },
    { id: "template_31255", width: 1056, height: 816 },
    { id: "template_31258", width: 816, height: 1056 },
    { id: "template_31259", width: 816, height: 1056 },
    { id: "template_22581.jpg", width: 816, height: 1056 },
    { id: "template_31262", width: 816, height: 1056 },
    { id: "template_31264", width: 1056, height: 816 },
    { id: "template_31266", width: 1056, height: 816 },
    { id: "template_31268", width: 1056, height: 816 },
    { id: "template_31276", width: 816, height: 1344 },
    { id: "template_31281", width: 816, height: 1056 },
    { id: "template_31282", width: 816, height: 1056 },
    { id: "template_31290", width: 1056, height: 816 },
    { id: "template_31292", width: 816, height: 1056 },
    { id: "template_31293", width: 816, height: 1344 },
    { id: "template_8439.jpg", width: 816, height: 1400 },
    { id: "template_3143.jpg", width: 816, height: 3950 },
    { id: "template_17040.jpg", width: 1056, height: 1632 },
    { id: "template_9048.jpg", width: 1056, height: 1632 },
    { id: "template_4641.jpg", width: 1056, height: 1632 },
    { id: "template_16404.jpg", width: 1056, height: 1632 },
    { id: "template_18964.jpg", width: 1056, height: 1632 },
    { id: "template_9229.jpg", width: 1056, height: 1632 },
    { id: "template_1909.jpg", width: 1056, height: 1632 },
    { id: "template_15134.jpg", width: 816, height: 3813 },
    { id: "template_19766.jpg", width: 816, height: 1056 },
    { id: "template_2093.jpg", width: 816, height: 2250 },
    { id: "template_11171.jpg", width: 816, height: 1900 },
    { id: "template_19509.jpg", width: 816, height: 2000 },
    { id: "template_17688.jpg", width: 816, height: 1056 },
    { id: "template_8213.jpg", width: 816, height: 1056 },
    { id: "template_16473.jpg", width: 816, height: 1056 },
    { id: "template_3948.jpg", width: 816, height: 4468 },
    { id: "template_49.jpg", width: 816, height: 1056 },
    { id: "template_1016.jpg", width: 816, height: 1056 },
    { id: "template_19593.jpg", width: 1056, height: 816 },
    { id: "template_1372.jpg", width: 1056, height: 816 },
    { id: "template_6591.jpg", width: 1056, height: 816 },
    { id: "template_8156.jpg", width: 816, height: 1056 },
    { id: "template_6699.jpg", width: 816, height: 1850 },
    { id: "template_15081.jpg", width: 1056, height: 1632 },
    { id: "template_6911.jpg", width: 1056, height: 1632 },
    { id: "template_14837.jpg", width: 1056, height: 1632 },
    { id: "template_23554.jpg", width: 1056, height: 1632 },
    { id: "template_20476.jpg", width: 1056, height: 1632 },
    { id: "template_5241.jpg", width: 1056, height: 1632 },
    { id: "template_6809.jpg", width: 1056, height: 1632 },
    { id: "template_6491.jpg", width: 1056, height: 1632 },
    { id: "template_18820.jpg", width: 1056, height: 1632 },
    { id: "template_13924.jpg", width: 1056, height: 1632 },
    { id: "template_18281.jpg", width: 1056, height: 1632 },
    { id: "template_13299.jpg", width: 816, height: 1056 },
    { id: "template_18781.jpg", width: 816, height: 4900 },
    { id: "template_16409.jpg", width: 816, height: 1056 },
    { id: "template_22510.jpg", width: 816, height: 1056 },
    { id: "template_1507.jpg", width: 816, height: 2500 },
    { id: "template_11426.jpg", width: 816, height: 1874 },
    { id: "template_16645.jpg", width: 816, height: 1056 },
    { id: "template_4333.jpg", width: 816, height: 1652 },
    { id: "template_13361.jpg", width: 816, height: 1056 },
    { id: "template_19799.jpg", width: 816, height: 1056 },
    { id: "template_8598.jpg", width: 1056, height: 816 },
    { id: "template_23095.jpg", width: 816, height: 1056 },
    { id: "template_19564.jpg", width: 816, height: 1056 },
    { id: "template_22500.jpg", width: 816, height: 1056 },
    { id: "template_5737.jpg", width: 816, height: 1056 },
    { id: "template_15912.jpg", width: 816, height: 3250 },
    { id: "template_13042.jpg", width: 816, height: 1056 },
    { id: "template_6985.jpg", width: 1056, height: 816 },
    { id: "template_31297", width: 816, height: 1056 },
    { id: "template_31301", width: 816, height: 1056 },
    { id: "template_31306", width: 816, height: 1056 },
    { id: "template_31308", width: 816, height: 1056 },
    { id: "template_31310", width: 816, height: 1056 },
    { id: "template_31311", width: 816, height: 1056 },
    { id: "template_31317", width: 1056, height: 816 },
    { id: "template_31321", width: 816, height: 1056 },
    { id: "template_31324", width: 816, height: 1056 },
    { id: "template_8968.jpg", width: 816, height: 2500 },
    { id: "template_31326", width: 816, height: 1056 },
    { id: "template_31332", width: 816, height: 1056 },
    { id: "template_31333", width: 816, height: 1056 },
    { id: "template_31336", width: 1056, height: 816 },
    { id: "template_31341", width: 1056, height: 816 },
    { id: "template_31344", width: 816, height: 1056 },
    { id: "template_31347", width: 1056, height: 816 },
    { id: "template_31348", width: 816, height: 1056 },
    { id: "template_31352", width: 1056, height: 816 },
    { id: "template_31357", width: 1056, height: 816 },
    { id: "template_20006.jpg", width: 816, height: 1056 },
    { id: "template_14620.jpg", width: 1056, height: 2150 },
    { id: "template_19012.jpg", width: 816, height: 1056 },
    { id: "template_13003.jpg", width: 1056, height: 1632 },
    { id: "template_6832.jpg", width: 1056, height: 1632 },
    { id: "template_22494.jpg", width: 1056, height: 1632 },
    { id: "template_5765.jpg", width: 1056, height: 1632 },
    { id: "template_13886.jpg", width: 1056, height: 1632 },
    { id: "template_4620.jpg", width: 1056, height: 1632 },
    { id: "template_18448.jpg", width: 1056, height: 1632 },
    { id: "template_7825.jpg", width: 1056, height: 1632 },
    { id: "template_21043.jpg", width: 1056, height: 1632 },
    { id: "template_6599.jpg", width: 1056, height: 1632 },
    { id: "template_13891.jpg", width: 1056, height: 1632 },
    { id: "template_11550.jpg", width: 1056, height: 1632 },
    { id: "template_16254.jpg", width: 816, height: 1874 },
    { id: "template_5423.jpg", width: 816, height: 1056 },
    { id: "template_17896.jpg", width: 816, height: 1650 },
    { id: "template_12976.jpg", width: 816, height: 2700 },
    { id: "template_418.jpg", width: 816, height: 4303 },
    { id: "template_10837.jpg", width: 1056, height: 816 },
    { id: "template_10845.jpg", width: 816, height: 1056 },
    { id: "template_16328.jpg", width: 816, height: 1056 },
    { id: "template_6093.jpg", width: 1056, height: 816 },
    { id: "template_2112.jpg", width: 1056, height: 816 },
    { id: "template_20707.jpg", width: 1056, height: 816 },
    { id: "template_15203.jpg", width: 816, height: 1350 },
    { id: "template_3549.jpg", width: 816, height: 1056 },
    { id: "template_6119.jpg", width: 1056, height: 1632 },
    { id: "template_1986.jpg", width: 1056, height: 1632 },
    { id: "template_13131.jpg", width: 1056, height: 1632 },
    { id: "template_7572.jpg", width: 1056, height: 1632 },
    { id: "template_16225.jpg", width: 1056, height: 1632 },
    { id: "template_7192.jpg", width: 1056, height: 1632 },
    { id: "template_20028.jpg", width: 1056, height: 1632 },
    { id: "template_18706.jpg", width: 1056, height: 1632 },
    { id: "template_12327.jpg", width: 1056, height: 1632 },
    { id: "template_5813.jpg", width: 1056, height: 816 },
    { id: "template_17061.jpg", width: 816, height: 1056 },
    { id: "template_18528.jpg", width: 816, height: 2350 },
    { id: "template_2693.jpg", width: 816, height: 1344 },
    { id: "template_15817.jpg", width: 816, height: 1056 },
    { id: "template_22241.jpg", width: 816, height: 1056 },
    { id: "template_7328.jpg", width: 816, height: 1344 },
    { id: "template_21242.jpg", width: 816, height: 1056 },
    { id: "template_5191.jpg", width: 1056, height: 816 },
    { id: "template_11181.jpg", width: 816, height: 1056 },
    { id: "template_2439.jpg", width: 816, height: 1056 },
    { id: "template_18578.jpg", width: 816, height: 1056 },
    { id: "template_16916.jpg", width: 816, height: 1056 },
    { id: "template_507.jpg", width: 1056, height: 816 },
    { id: "template_18308.jpg", width: 1056, height: 816 },
    { id: "template_2848.jpg", width: 816, height: 1056 },
    { id: "template_1048.jpg", width: 816, height: 1056 },
    { id: "template_31358", width: 816, height: 1344 },
    { id: "982143ea-9c8f-4fed-be0c-bd5e9d47e613", width: 816, height: 1056 },
    { id: "template_31361", width: 816, height: 1056 },
    { id: "template_31362", width: 1056, height: 816 },
    { id: "template_31363", width: 816, height: 1056 },
    { id: "template_31364", width: 816, height: 1056 },
    { id: "template_31365", width: 816, height: 1056 },
    { id: "template_31367", width: 816, height: 1056 },
    { id: "template_31368", width: 816, height: 1056 },
    { id: "template_31370", width: 816, height: 1056 },
    { id: "template_31372", width: 816, height: 1056 },
    { id: "template_22663.jpg", width: 816, height: 2000 },
    { id: "template_14445.jpg", width: 1056, height: 1632 },
    { id: "template_14849.jpg", width: 816, height: 1970 },
    { id: "template_11418.jpg", width: 816, height: 3000 },
    { id: "template_5768.jpg", width: 652, height: 3366 },
    { id: "template_16705.jpg", width: 816, height: 1056 },
    { id: "template_7165.jpg", width: 816, height: 4000 },
    { id: "template_1973.jpg", width: 1056, height: 816 },
    { id: "template_1616.jpg", width: 816, height: 2480 },
    { id: "template_6258.jpg", width: 816, height: 1056 },
    { id: "template_16147.jpg", width: 816, height: 1420 },
    { id: "template_1649.jpg", width: 816, height: 1056 },
    { id: "template_17704.jpg", width: 1056, height: 1632 },
    { id: "template_6149.jpg", width: 1056, height: 1632 },
    { id: "template_19137.jpg", width: 1056, height: 1632 },
    { id: "template_4634.jpg", width: 1056, height: 1632 },
    { id: "template_3359.jpg", width: 816, height: 2210 },
    { id: "template_9336.jpg", width: 816, height: 2360 },
    { id: "template_18251.jpg", width: 816, height: 1056 },
    { id: "template_7659.jpg", width: 816, height: 3950 },
    { id: "template_186.jpg", width: 816, height: 1850 },
    { id: "template_3762.jpg", width: 816, height: 2850 },
    { id: "template_12177.jpg", width: 816, height: 2700 },
    { id: "template_22067.jpg", width: 816, height: 1056 },
    { id: "template_10997.jpg", width: 816, height: 1490 },
    { id: "template_743.jpg", width: 816, height: 1560 },
    { id: "template_4718.jpg", width: 830, height: 1355 },
    { id: "template_20095.jpg", width: 816, height: 1500 },
    { id: "template_5434.jpg", width: 816, height: 1056 },
    { id: "template_15374.jpg", width: 816, height: 1056 },
    { id: "template_14791.jpg", width: 1000, height: 2603 },
    { id: "template_9233.jpg", width: 816, height: 2700 },
    { id: "template_4951.jpg", width: 1632, height: 1056 },
    { id: "template_6798.jpg", width: 816, height: 1550 },
    { id: "template_31224", width: 816, height: 1056 },
  ];
}
export const SELECTED_TEMPLATES = defaultTemplates;
