import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import { Button, Card, CircularProgress, IconButton, Link, Menu, MenuItem } from "@mui/material";
import "./Designs.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { fetchDesigns, updateDesignServer } from "./utils/ServerUtils";

export function Designs({}) {
  const [designs, setDesigns] = useState([]);
  const [page, setPage] = useState(0);
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  function loadMore() {
    setLoading(true);
    setPage(page + 1);
    fetchDesigns(page + 1)
      .then((res) => {
        setLoading(false);
        if (page >= res.total_pages) {
          setLoadMoreVisible(false);
        }
        setDesigns([...designs, ...res.designs]);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching designs:", error);
      });
  }

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 20,
          justifyContent: "start",
          alignItems: "start",
          padding: 20,
          overflowY: "scroll",
        }}
      >
        {designs?.map((design) => (
          <Design design={design} />
        ))}
        {!loading && designs?.length == 0 && <div>No designs yet. Create one now!</div>}
      </div>

      <div
        style={{
          padding: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        {!loading && loadMoreVisible && (
          <Button
            onClick={loadMore}
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#f0f0f0",
              color: "#333",
            }}
          >
            Load more designs
          </Button>
        )}
      </div>
    </div>
  );
}

function Design({ design }) {
  return (
    <Card style={{ width: 200 }} className="design">
      <div
        style={{
          width: 200,
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          src={preview(design)}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 15,
            textAlign: "center",
          }}
        >
          {design.title || "Untitled"}
        </div>
        {/* <ThreeDots design={design} onComplete={() => {}} /> */}
      </div>
      <div className="edit">
        <Button
          variant="contained"
          onClick={() => {
            window.open(`/design/${design.id}`, "_blank");
          }}
        >
          Edit
        </Button>
      </div>
    </Card>
  );
}
function ThreeDots({ design, onComplete }) {
  const { deleteDesign } = useSVG();

  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <div className="threedots">
      <IconButton
        onClick={(e) => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            deleteDesign(design.id, onComplete);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

function preview(design) {
  if (design.metainfo?.svg_url) return design.metainfo.svg_url;
  if (design.metainfo?.png_urls?.length > 0) return design.metainfo.png_urls[0];
  return design.preview;
}
