import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, IconButton } from "@mui/material";
import { useSVG } from "./SVGContext";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import { arrange } from "./utils/ArrangeUtils";

const style1 = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "stretch",
  alignItems: "stretch",
  gap: 10,

  marginTop: 10,
  marginBottom: 20,
};
const style2 = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "stretch",
  gap: 10,
};
const btn = {
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

export function AlignPanel({}) {
  const z = useSVG();

  return (
    <>
      <div>
        <div>Position</div>
        <div style={style1}>
          <div style={style2}>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "bringforward")}>
              <KeyboardArrowUpIcon />
              Forward
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "bringtofront")}>
              <KeyboardArrowUpIcon />
              Fully Front
            </Button>
          </div>

          <div style={style2}>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "sendbackward")}>
              <KeyboardArrowDownIcon />
              Backward
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "sendtoback")}>
              <KeyboardArrowDownIcon />
              Fully Back
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div>Align To Page</div>
        <div style={style1}>
          <div style={style2}>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "top")}>
              <AlignVerticalTopIcon />
              Top
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "middle")}>
              <AlignVerticalCenterIcon />
              Middle
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "bottom")}>
              <AlignVerticalBottomIcon />
              Bottom
            </Button>
          </div>

          <div style={style2}>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "left")}>
              <AlignHorizontalLeftIcon />
              Left
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "center")}>
              <AlignHorizontalCenterIcon />
              Center
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "right")}>
              <AlignHorizontalRightIcon />
              Right
            </Button>
          </div>
        </div>
      </div>

      {z.selectedIds?.length > 1 && (
      <div>
        <div>Align To Each Other</div>
        <div style={style1}>
          <div style={style2}>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "eachother-top")}>
              <AlignVerticalTopIcon />
              Top
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "eachother-middle")}>
              <AlignVerticalCenterIcon />
              Middle
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "eachother-bottom")}>
              <AlignVerticalBottomIcon />
              Bottom
            </Button>
          </div>

          <div style={style2}>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "eachother-left")}>
              <AlignHorizontalLeftIcon />
              Left
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "eachother-center")}>
              <AlignHorizontalCenterIcon />
              Center
            </Button>
            <Button variant="outlined" size="small" style={btn} onClick={() => arrange(z, "eachother-right")}>
              <AlignHorizontalRightIcon />
              Right
            </Button>
          </div>
        </div>
      </div>)}
    </>
  );
}
