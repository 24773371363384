import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const calendarData = {
    "2024-12-01": [
        {'img': 'https://cdn.makemybrand.ai/10fb5eec.svg?v=v'},
    ],
    "2024-12-02": [
        {'title': 'Hair Coloring Guide', 'type': 'how-to'}
    ],
    "2024-12-03": [
        {'img': 'https://cdn.makemybrand.ai/38f850bf.svg?v=v',
        'title': 'Waxing - Myth vs Fact', 'type': 'Myth vs Fact'}
    ],
}


const ContentCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date("09-09-2024"));
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});

  const handleDayClick = (date) => {
    setOpenDialog(true);
  };

  const handleSave = () => {
    setOpenDialog(false);
  };

  const renderCalendar = () => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const header = Array.from({ length: 7 }, (_, i) => {
        return (
            <Grid
                key={i}
                item
                xs={12 / 7}
                style={{ border: "0.5px solid #ddd", padding: "8px" }}
            >
                {daysOfWeek[i]}
            </Grid>
            );
    });

    const daysInMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    ).getDate();

    let startingDayOfWeek = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
        ).getDay(); // 1 for monday, 2 for tuesday, etc
    if (startingDayOfWeek == 0) startingDayOfWeek = 7;

    console.log(startingDayOfWeek)

    // find offset days in first week such that the first day of the month is in the correct position
    const offsetDays = Array.from({ length: startingDayOfWeek-1 }, (_, i) => {
        return (
            <Grid
                key={i}
                item
                xs={12 / 7}
                style={{ border: "0.5px solid #ddd", padding: "8px" }}
            >
            </Grid>
        );    
    });

    const days = Array.from({ length: daysInMonth }, (_, i) => {
      const date = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        i + 1
      );
      const formattedDate = date.toISOString().split("T")[0];

      return (
        <Grid
          key={i}
          item
          xs={12 / 7}
          style={{
            border: "0.5px solid #ddd",
            padding: "8px",
            cursor: "pointer",
          }}
          onClick={() => handleDayClick(formattedDate)}
        >
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <span style={{
                background: "#eee",
                borderRadius: "100%",
                padding: "8px 12px",

            }}>{date.getDate()}</span>
          </div>

          <div>
            {calendarData[formattedDate] && calendarData[formattedDate].map((item, index) => {
                if (item.img) {
                    return <img src={item.img} key={index} style={{width: "100%"}}/>
                } else {
                    return <div key={index} style={{margin: "8px 0"}}>
                        <div>{item.title}</div>
                        <div>{item.type}</div>
                    </div>
                }
            })}
          </div>
          
        </Grid>
      );
    });

    return <Grid container>
        {header}
        {offsetDays}
        {days}
        </Grid>;
  };

  return (
    <div>

      <SelectableMonth selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <div style={{ margin: "16px 0" }}>{renderCalendar()}</div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Content</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Title"
            value={currentEdit.title || ""}
            onChange={(e) =>
              setCurrentEdit((prev) => ({ ...prev, title: e.target.value }))
            }
            style={{ marginBottom: "16px" }}
          />
          <TextField
            fullWidth
            label="Type"
            value={currentEdit.type || ""}
            onChange={(e) =>
              setCurrentEdit((prev) => ({ ...prev, type: e.target.value }))
            }
            style={{ marginBottom: "16px" }}
          />
          <Button
            variant="contained"
            onClick={handleSave}
            style={{ marginTop: "16px" }}
          >
            Save
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContentCalendar;

function SelectableMonth({selectedDate, setSelectedDate}) {
    const [year, setYear] = useState(selectedDate.getFullYear());
    const [monthStr, setMonthStr] = useState(selectedDate.toLocaleString('default', { month: 'short' }));

    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        setYear(selectedDate.getFullYear());
        setMonthStr(selectedDate.toLocaleString('default', { month: 'short' }));
    }, [selectedDate])

    function onClick() {
        setOpen(true);
    }
    function onLeft() {
        if (selectedDate.getFullYear() == 2023) return;

        setSelectedDate(new Date(selectedDate.getFullYear()-1, selectedDate.getMonth(), 1));
    }
    function onRight() {
        if (selectedDate.getFullYear() == 2025) return;

        setSelectedDate(new Date(selectedDate.getFullYear()+1, selectedDate.getMonth(), 1));
    }

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }} onClick={onClick}>
                {monthStr}
            </div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <IconButton onClick={onLeft}><ChevronLeftIcon /></IconButton>
                        {year}
                        <IconButton onClick={onRight}><ChevronRightIcon/></IconButton>
                        
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        {Array.from({length: 12}, (_, i) => {
                            const month = new Date(year, i, 1);
                            const monthStr = month.toLocaleString('default', { month: 'short' });
                            return (
                                <Grid item xs={4} key={i} style={{cursor: "pointer"}} onClick={() => {
                                    setSelectedDate(month);
                                    setOpen(false);
                                }}>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        border: "solid 1px #ddd",
                                        borderRadius: "100%",
                                        padding: "14px 22px",
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        background: monthStr === selectedDate.toLocaleString('default', { month: 'short' }) ? "#555" : "white",
                                        color: monthStr === selectedDate.toLocaleString('default', { month: 'short' }) ? "white" : "black",
                                    }}>
                                        {monthStr}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>

            </Dialog>
        </>
    )
}