import { createRef, useEffect, useState } from "react";
import { iconIdToIconSrc } from "./library/ImagesPanel";
import { Dialog } from "@material-ui/core";
import { useSVG } from "./SVGContext";
import { DialogContent, DialogTitle } from "@mui/material";

export function ImageOptionsDialog({  }) {
    const {imageOptions, setImageOptions, addImage} = useSVG();

    if (!imageOptions) return null;

    const gap = 20, padding = 20;
    const cols = 4;
    // const w = item.width * cols + gap * cols + 2 * padding;
    const rows = Math.ceil(imageOptions.length / cols);
    // const h = item.height * rows + gap * rows + 2 * padding;

  return (
    <Dialog open={imageOptions} onClose={() => setImageOptions(null)}>
        <DialogTitle>Click on an image to select it</DialogTitle>
        <DialogContent>
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: gap,
          justifyContent: "center",
          padding: padding,
          background: "white",
          border: "1px solid black",
          borderRadius: 10,
        }}
      >
        {imageOptions.map((option, index) => (
          <div
            className="svg-option-container"
            key={index}
            style={{
              width: 100,
              height: 100,
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              // note: replacingItemID is already set when you triggered this dialog 
              option.src = option.url;
              addImage(option);
              setImageOptions(null);
            }}
          >
            <img
            // style="max-width:400px;height:auto;object-fit:contain;"
              src={option.url}
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
                
              }}
            />
          </div>
        ))}
      </div>
      </DialogContent>
    </Dialog>
  );
}