import { useRef, useState } from "react";
import { useSVG } from "../SVGContext";
import ImageZoomer, { getPoints } from "../ImageZoomer";
import { getItemFilterString } from "../library/ImageAdjustPanel";
import { OptionsView } from "./VectorView";

export function MpImageView({ item, overlay }) {
  if (!item.imageRect) return null; // qr code

  const { croppingItemId, addImage, setImageOptions, setReplacingItemId } = useSVG();

  if (overlay && croppingItemId !== item.id) return;

  if (item.type == "mpimage") {
    const frameId = item.customFrame?.id;
  
    const [showOptions, setShowOptions] = useState(false);
    const optionsOptions = item['image-options']
      ? {
          options: item['image-options'],
          item,
          add: (option) => {
            addImage(option, item.id);
          },
        }
      : null;
    return (
      <>
        {/* {showOptions && optionsOptions && <OptionsView {...optionsOptions} />} */}

        {item.customFrame && (
          <defs>
            <clipPath id={item.customFrame.id} clipPathUnits={item.customFrame.clipPathUnits || "objectBoundingBox"} dangerouslySetInnerHTML={{ __html: item.customFrame.innerHTML }}></clipPath>
          </defs>
        )}
       <image
            id={item.id}
            x={item.x}
            y={item.y}
            height={item.height}
            width={item.width}
            style={{
              // opacity: 0.3
              clipPath: frameId ? `url(#${frameId})` : "",
            }}
            draggable="false"
            href={item.url}
            preserveAspectRatio="xMidYMid slice" 
            onDoubleClick={() => {
              setReplacingItemId(item.id);
              setImageOptions(item['image-options']);
              console.log("image-options", item['image-options']);
            }}
          />
      </>
    );
  }
}
