// @ts-nocheck
import { Box, Button, ClickAwayListener, createSvgIcon, IconButton, Menu, MenuItem, Popper, Slider, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import { findAncestralTransform } from "../utils/PointerUtils";
import CropRoundedIcon from "@mui/icons-material/CropRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { RotateBtn } from "./RotateBtn";
import { TopbarCommons } from "./TopbarCommons";
import MyButton from "./MyButton";
import { FRAME_DEFS } from "../values/constants";
import { CropDialog } from "../CropDialog";

export const TopbarImage = ({ item }) => {
  return (
    <>
      <Replace item={item} />
      {/* <Frame item={item} /> */}
      <Crop item={item} />
      {/* <Adjust item={item} /> */}
      <Resize item={item} />
    </>
  );
};

function Replace({ item }) {
  const { replacingItemId, setReplacingItemId, clearTopbarSelectedButtons, setLeftPanel } = useSVG();
  return (
    <MyButton
      size="small"
      variant="outlined"
      isPressed={replacingItemId}
      onClick={() => {
        clearTopbarSelectedButtons();
        setReplacingItemId(item.id);
        setLeftPanel("images");
      }}
    >
      Replace
    </MyButton>
  );
}
function Crop({ item }) {
  const { croppingItemId, setCroppingItemId, clearTopbarSelectedButtons, items, update } = useSVG();
  const [open, setOpen] = useState(false);

  return (
    <>
    <IconButton
      size="small"
      variant="outlined"
      onClick={() => {
        clearTopbarSelectedButtons();
        setOpen(true);
      }}
    >
      <CropRoundedIcon />
      <span>CROP</span>
    </IconButton>
    <CropDialog open={open} setOpen={setOpen} />
    </>
  );
}

function Adjust({ item }) {
  const { setLeftPanel, clearTopbarSelectedButtons } = useSVG();
  return (
    <IconButton
      size="small"
      variant="outlined"
      onClick={() => {
        clearTopbarSelectedButtons();
        setLeftPanel("imageAdjust");
      }}
    >
      <AutoAwesomeRoundedIcon />
    </IconButton>
  );
}
function Frame({ item }) {
  const { setShowImageFramePanel, clearTopbarSelectedButtons, items, groups, defs, selectedIds, update } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  const [topbarFrameIcon, setTopbarFrameIcon] = useState(null);

  useEffect(() => {
    if (item.frame) {
      const match = frames.find((f) => f.name == item.frame);
      setTopbarFrameIcon(match?.icon);
    } else {
      setTopbarFrameIcon(null);
    }
  }, [item, items]);

  function setFrame(frame) {
    items[item.id].customFrame = {
      innerHTML: frame.innerHTML,
      id: item.id + "-frame",
      scale: { x: 1, y: 1 },
    };
    update({ items, groups, defs, selectedIds });
  }
  return (
    <>
      <IconButton
        size="small"
        variant="outlined"
        onClick={(e) => {
          clearTopbarSelectedButtons();
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        style={{
          background: topbarFrameIcon ? "#ccc" : "",
        }}
      >
        {topbarFrameIcon ? topbarFrameIcon : <Circle />}
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{
          width: 180,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",

            backgroundColor: "white",
            border: "solid 1px #ccc",
            borderRadius: 5,
            boxShadow: "0px 5px 5px #929292",
            marginTop: 5,
          }}
        >
          {frames.map((frame, i) => (
            <IconButton
              key={i}
              onClick={() => {
                setFrame(frame);
                setAnchorEl(null);
              }}
              style={{
                background: frame.name == item.frame ? "#ccc" : "",
              }}
            >
              {frame.icon}
            </IconButton>
          ))}
        </Box>
      </Popper>
    </>
  );
}

const stroke = "#333",
  strokeWidth = 1;

const Circle = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <circle cx="12" cy="12" r="10" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  </svg>,
  "Circle",
);
const Rect = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <rect x="4" y="6" width="18" height="12" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Rect",
);
const Square = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <rect x="4" y="4" width="16" height="16" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Square",
);
const Ellipse = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <ellipse cx="12" cy="12" rx="7" ry="10" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  </svg>,
  "Ellipse",
);
const Triangle = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <polygon points="12,2 2,22 22,22" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Triangle",
);
const Star = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <path d="M12 2 l 2 6 l 6 0 l -5 3 l 2 6 l -6 -4 l -6 4 l 2 -6 l -5 -3 l 6 0 Z" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  </svg>,
  "Star",
);

const Diamond = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <polygon points="12,2 22,12 12,22 2,12" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Diamond",
);

const Hexagon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <polygon points="12,2 22,7 22,17 12,22 2,17 2,7" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Hexagon",
);
export const frames = [
  {
    name: "Rectangle",
    icon: <Rect />,
    innerHTML: `<rect x="0" y="0" width="1" height="1" />`,
  },
  {
    name: "Square",
    icon: <Square />,
    innerHTML: `<rect x="0" y="0" width="1" height="1" />`,
  },
  {
    name: "Circle",
    icon: <Circle />,
    innerHTML: `<circle cx="0.5" cy="0.5" r="0.5" />`,
  },
  {
    name: "Ellipse",
    icon: <Ellipse />,
    innerHTML: `<ellipse cx="0.5" cy="0.5" rx="0.5" ry="0.5" />`,
  },
  {
    name: "Diamond",
    icon: <Diamond />,
    innerHTML: `<polygon points="0.5 0, 1 0.5, 0.5 1, 0 0.5" />`,
  },
  {
    name: "Triangle",
    icon: <Triangle />,
    innerHTML: `<polygon points="0.5 0, 0 1, 1 1" />`,
  },
  {
    name: "Star",
    icon: <Star />,
    innerHTML: `<polygon points="0.5 0, 0.63 0.38, 1 0.38, 0.69 0.59, 0.82 1, 0.5 0.75, 0.18 1, 0.31 0.59, 0 0.38, 0.37 0.38"></polygon>`,
  },
  {
    name: "Hexagon",
    icon: <Hexagon />,
    innerHTML: `<polygon points="0.5 0, 0.8 0.25, 1 0.5, 0.8 0.75, 0.5 1, 0.2 0.75, 0 0.5, 0.2 0.25" />`,
  },
];

function Resize({ item }) {
  const { clearTopbarSelectedButtons, items, groups, defs, selectedIds, update } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        size="small"
        variant="outlined"
        onClick={(e) => {
          clearTopbarSelectedButtons();
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <AspectRatioIcon />
        <span>RESIZE</span>
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{
          width: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",

            backgroundColor: "white",
            border: "solid 1px #ccc",
            borderRadius: 5,
            boxShadow: "0px 5px 5px #929292",
            marginTop: 5,
            padding: 20,
          }}
        >
          <TextField
            type="number"
            label="Width"
            value={item.widthPx}
            onChange={(e) => {
              // find aspect ratio
              const aspectRatio = item.widthPx / item.heightPx;

              item.widthPx = e.target.value;
              item.heightPx = item.widthPx / aspectRatio;
              items[item.id] = item;
              update({ items, groups, defs, selectedIds });
            }}
            style={{
              width: 120,
            }}
          />
          <TextField
            type="number"
            label="Height"
            value={item.heightPx}
            onChange={(e) => {
              // find aspect ratio
              const aspectRatio = item.widthPx / item.heightPx;
              item.heightPx = e.target.value;
              item.widthPx = item.heightPx * aspectRatio;
              items[item.id] = item;
              update({ items, groups, defs, selectedIds });
            }}
            style={{
              width: 120,
            }}
          />
        </div>
      </Popper>
    </>
  );
}
