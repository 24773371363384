// @ts-nocheck
import React, { memo, useCallback, useEffect, useState } from "react";
import { Tab, Tabs, Box, IconButton, Tooltip, BottomNavigation, useMediaQuery, BottomNavigationAction, ClickAwayListener, ThemeProvider, Popper } from "@mui/material";
import { DARKTHEME, THEME_BG, THEME_BG_DULLER, THEME_TEXT } from "./SVGContext";
import { cap } from "./utils/utils";
import IconSearch from "./library/IconSearch";
import TemplateSearch from "./library/TemplateSearch";
import { ShapesPanel } from "./library/ShapesPanel";
import { ImagesPanel } from "./library/ImagesPanel";
import { TextPanel } from "./library/TextPanel";
import InterestsIcon from "@mui/icons-material/Interests";
import TitleIcon from "@mui/icons-material/Title";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import ImageIcon from "@mui/icons-material/Image";
import { BackgroundColorPanel } from "./library/BackgroundColorPanel";
import { AlignPanel } from "./AlignPanel";
import { ImageAdjustPanel } from "./library/ImageAdjustPanel";
import { Pages } from "./Pages";
import { PreviewPanel } from "./PreviewPanel";
import { BlocksPanel } from "./library/BlocksPanel";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { BrandPanel } from "./library/BrandPanel";
import CloseIcon from "@mui/icons-material/Close";
import { LayoutMarkingPanel } from "./library/LayoutMarkingPanel";
import LazyFonts from "./LazyFonts";

const LeftPanel = memo(({ leftPanel, setLeftPanel, label, isMobile, onTemplateSelect, addShape, userInfo }) => {
  // console.log("rendering left panel ", new Date().getTime());

  let panels = [
    {
      name: "shapes",
      icon: <InterestsIcon />,
      tooltip: label("Shapes"),
    },
    {
      name: "brand",
      icon: <ColorLensIcon />,
      tooltip: label("Your Brand"),
    },
    {
      name: "texts",
      icon: <TitleIcon />,
      tooltip: label("Text"),
    },
    // {
    //   name: "templates",
    //   icon: <WebAssetIcon />,
    //   tooltip: label("Templates"),
    // },
    {
      name: "images",
      icon: <ImageIcon />,
      tooltip: label("Images"),
    },
  ];
  if (userInfo?.permissions.includes("designer")) {
    panels = [{
      name: "layout",
      icon: <WebAssetIcon />,
      tooltip: label("Config"),
    }, ...panels]
  }
  if (userInfo?.permissions.includes("can_ai_generate")) {
    panels.push({
      name: "blocks",
      icon: <WidgetsIcon />,
      tooltip: label("Blocks"),
    });
  }

  if (isMobile) {
    return <BottomPanel {...{ onTemplateSelect, leftPanel, setLeftPanel }} />;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
          backgroundColor: THEME_BG_DULLER,
        }}
      >
        {panels.map((it, index) => (
          <PanelButton key={index} it={it} index={index} leftPanel={leftPanel} setLeftPanel={setLeftPanel} />
        ))}
      </div>

      {leftPanel && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",

            width: 300,
            minWidth: 300,
            overflowX: "hidden",

            color: THEME_TEXT,
            backgroundColor: THEME_BG,
            paddingTop: 10,

            position: "relative",
          }}
          className="leftpanel-details"
        >
          {/* {leftPanel == "templates" && <TemplateSearch onTemplateSelect={onTemplateSelect} />} */}
          {leftPanel === "brand" && <BrandPanel />}
          {leftPanel == "layout" && <LayoutMarkingPanel />}

          {leftPanel == "shapes" && <ShapesPanel addShape={addShape} />}
          {leftPanel == "texts" && <TextPanel />}
          {leftPanel == "images" && <ImagesPanel />}
          {leftPanel == "colors" && <BackgroundColorPanel />}
          {leftPanel == "imageAdjust" && (
            <div style={{ padding: 15 }}>
              <ImageAdjustPanel />
            </div>
          )}
          {leftPanel == "align" && (
            <div style={{ padding: 15 }}>
              <AlignPanel />
            </div>
          )}
          {leftPanel == "pages" && <Pages />}
          {leftPanel == "preview" && <PreviewPanel />}
          {leftPanel == "blocks" && <BlocksPanel />}
          {leftPanel == "fonts" && <LazyFonts />}

          <CloseIcon
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
            }}
            onClick={() => setLeftPanel(null)}
          />
        </div>
      )}
    </>
  );
});

export default LeftPanel;

function BottomPanel({ onTemplateSelect, leftPanel, setLeftPanel }) {
  return (
    <ClickAwayListener onClickAway={() => {}}>
      <div
        style={{
          zIndex: 5,
        }}
      >
        <Popper
          open={leftPanel !== null}
          anchorEl={document.querySelector(".bottompanel")}
          style={{
            color: THEME_TEXT,
            backgroundColor: THEME_BG,
            height: leftPanel == null ? 0 : "60vh",
            overflowY: "scroll",
            paddingTop: 10,
            width: "100%",
            boxShadow: "rgb(255 255 255) 0px 0px 15px 0px",
          }}
        >
          {leftPanel === "shapes" && <ShapesPanel />}
          {leftPanel === "brand" && <BrandPanel />}
          {leftPanel === "texts" && <TextPanel />}
          {/* {leftPanel === "templates" && <TemplateSearch onTemplateSelect={onTemplateSelect} />} */}
          {leftPanel === "images" && <ImagesPanel />}
          {leftPanel == "colors" && <BackgroundColorPanel />}
          {leftPanel == "imageAdjust" && (
            <div style={{ padding: 15 }}>
              <ImageAdjustPanel />
            </div>
          )}
          {leftPanel == "align" && (
            <div style={{ padding: 15 }}>
              <AlignPanel />
            </div>
          )}
          {leftPanel == "pages" && <Pages />}
          {leftPanel == "preview" && <PreviewPanel />}
          {leftPanel == "blocks" && <BlocksPanel />}
        </Popper>

        <BottomNavigation className="bottompanel" value={leftPanel} onChange={(event, newValue) => setLeftPanel(leftPanel == newValue ? null : newValue)} showLabels style={{}}>
          {/* <BottomNavigationAction
            label="Shapes"
            value="shapes"
            icon={<InterestsIcon />}
            style={{
              backgroundColor: leftPanel === "shapes" ? THEME_BG : THEME_BG_DULLER,
            }}
          /> */}
          <BottomNavigationAction
            label="Texts"
            value="texts"
            icon={<TitleIcon />}
            style={{
              backgroundColor: leftPanel === "texts" ? THEME_BG : THEME_BG_DULLER,
            }}
          />
          <BottomNavigationAction
            label="Templates"
            value="templates"
            icon={<WebAssetIcon />}
            style={{
              backgroundColor: leftPanel === "templates" ? THEME_BG : THEME_BG_DULLER,
            }}
          />
          <BottomNavigationAction
            label="Images"
            value="images"
            icon={<ImageIcon />}
            style={{
              backgroundColor: leftPanel === "images" ? THEME_BG : THEME_BG_DULLER,
            }}
          />
        </BottomNavigation>
      </div>
    </ClickAwayListener>
  );
}

function PanelButton({ it, index, leftPanel, setLeftPanel }) {
  if (it.disabled) return;
  return (
    <div key={index}>
      {
        <Tooltip key={"tooltip_" + index} title={it.tooltip + (it.disabled ? " - Coming Soon" : "")} placement="right-end" TransitionProps={{ timeout: 0 }}>
          <IconButton
            className={"leftpanel-button " + (it.name === leftPanel ? "selected" : "")}
            disableRipple
            disabled={it.disabled}
            style={{
              opacity: it.disabled ? 0.1 : 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,

              width: "100%",
            }}
            onClick={() => {
              setLeftPanel(leftPanel == it.name ? null : it.name);
            }}
          >
            {it.icon}
            <span
              style={{
                fontSize: "0.5em",
              }}
            >
              {cap(it.name)}
            </span>
          </IconButton>
        </Tooltip>
      }
    </div>
  );
}
