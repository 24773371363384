import { Button, ButtonGroup, Divider, IconButton, InputLabel, ListSubheader, Menu, MenuItem, Select, Slider, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSVG } from "../SVGContext";
import { GoogleFontFamilies } from "../values/GoogleFontFamilies";
import React, { useEffect, useState } from "react";
import { allGoogleFonts } from "../values/all-google-fonts";
import AlignLeft from "@mui/icons-material/FormatAlignLeft";
import AlignCenter from "@mui/icons-material/FormatAlignCenter";
import AlignRight from "@mui/icons-material/FormatAlignRight";
import { ColorSquare } from "../ColorSquare";
import { addFontDef } from "../utils/utils";
import { RotateBtn } from "./RotateBtn";
import { FontsList } from "../FontsList";
import { TopbarCommons, TopBarNumber } from "./TopbarCommons";
import MyButton from "./MyButton";
import { updateBoldItalicUnderline, updateColor, updateTextAlign, updateTextSize } from "../utils/proseMirrorUtils";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";
import { TopbarFontSize } from "./TopbarFontSize";
import { extractTextColorFromPM } from "../ProseUtils";
import { TopbarLineHeight } from "./TopbarLineHeight";
import { TopbarTextWidthHeight } from "./TopbarTextWidthHeight";

const TopbarText = ({ item }) => {
  if (!item) return;

  return (
    <>
      <FontColor item={item} />
      <FontsList />
      <TopbarFontSize />
      <TopbarTextWidthHeight />

      <TopbarLineHeight />

      <BoldItalicUnderline item={item} />
      <Align item={item} />
    </>
  );
};

export default TopbarText;

export function updateAllSelectedTexts(items, selectedIds, fn, val, update) {
  for (let id in selectedIds) {
    const item = items[selectedIds[id]];
    if (item.proseMirrorData) {
      item.proseMirrorData = fn(item.proseMirrorData, val);
      item.html = generateHTML(item.proseMirrorData, EditorExtensions);
      items[selectedIds[id]] = item;
    }
  }
  update({ items });
}

function Align({ item }) {
  const { editor, editorState, items, selectedIds, update } = useSVG();

  const [anchorEl, setAnchor] = useState(null);

  let alignment = editorState["text-align"];

  return (
    <div>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        {(!alignment || alignment === "left") && <AlignLeft />}
        {alignment === "center" && <AlignCenter />}
        {alignment === "right" && <AlignRight />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {["Left", "Center", "Right"].map((choice, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              if (selectedIds.length > 1) {
                updateAllSelectedTexts(items, selectedIds, updateTextAlign, choice.toLowerCase(), update);
                return;
              }
              editor.chain().focus().setTextAlign(choice.toLowerCase()).run();
              editor.view.dispatch(editor.state.tr);

              setAnchor(null);
            }}
          >
            {choice === "Left" && <AlignLeft />}
            {choice === "Center" && <AlignCenter />}
            {choice === "Right" && <AlignRight />}
            <span style={{ marginLeft: 8 }}>{choice}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const editorValue = {
  B: "Bold",
  I: "Italic",
  U: "Underline",
};

function BoldItalicUnderline({ item }) {
  const { editor, items, selectedIds, update } = useSVG();
  const [isPressed, setIsPressed] = useState({});

  useEffect(() => {
    if (selectedIds.length > 1 && items[selectedIds[0]].proseMirrorData) {
      setIsPressed({
        bold: false,
        italic: false,
        underline: false,
      });
    } else {
      setIsPressed({
        bold: editor.isActive("bold"),
        italic: editor.isActive("italic"),
        underline: editor.isActive("underline"),
      });
    }
  }, [selectedIds]);

  return (
    <ButtonGroup>
      {["B", "I", "U"].map((t, i) => (
        <MyButton
          key={i}
          // size="small"
          isPressed={isPressed[t.toLowerCase()]}
          onClick={() => {
            if (selectedIds.length > 1) {
              const val = isPressed[t.toLowerCase()] ? false : true;
              updateAllSelectedTexts(
                items,
                selectedIds,
                updateBoldItalicUnderline,
                {
                  [editorValue[t].toLowerCase()]: val,
                },
                update,
              );
              setIsPressed({
                ...isPressed,
                [t.toLowerCase()]: val,
              });
              return;
            }
            const fn = "toggle" + editorValue[t];
            editor.chain().focus()[fn]().run();
          }}
        >
          <span
            style={{
              textDecoration: t,
            }}
          >
            {t}
          </span>
        </MyButton>
      ))}
    </ButtonGroup>
  );
}

function FontColor() {
  const { editor, editorState, items, selectedIds, update } = useSVG();
  const [color, setColor] = useState("#000");

  useEffect(() => {
    setColor(whichColor());
  }, [selectedIds]);

  return (
    <ColorSquare
      color={color}
      onChange={(color) => {
        if (editor.state?.selection) {
          editor.chain().focus().setColor(color.hex).run();
          editor.view.dispatch(editor.state.tr);
          return;
        }
        updateAllSelectedTexts(items, selectedIds, updateColor, color.hex, update);
      }}
    />
  );

  function whichColor() {
    if (editor.isFocused) {
      return editorState.color;
    } else if (selectedIds.length > 1) {
      return "#000";
    } else if (selectedIds.length === 1) {
      const node = items[selectedIds[0]].proseMirrorData;
      if (node) {
        return extractTextColorFromPM(node);
      }
    }
  }
}
