// @ts-nocheck

export const ShapeType = {
  Rectangle: "rectangle",
  Circle: "ellipse",
  Polygon: "polygon",
  Star: "star",
  Line: "line",
  Arc: "arc",
};

export const ChartType = {
  column: "column",
  bar: "bar",
  pie: "pie",
};

const rotateCursor = (deg) => `<svg height="24" width="24" viewBox="0 0 155 104" xmlns="http://www.w3.org/2000/svg"><path  d="M14,94 C 62 96, 134 82, 131 10 " fill="transparent" stroke="black" stroke-width="16" transform-origin="center" transform="rotate(${deg})"/><path d="M108,31 C 108 31, 131 9, 131 9 C 131 9, 145 36, 145 36 " fill="transparent" stroke="black" stroke-width="16" transform-origin="center" transform="rotate(${deg})"/></svg>`;

// const rotate1 = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><circle cx="16" cy="16" r="12" fill="red"/></svg>`;

// https://www.w3schools.com/cssref/tryit.php?filename=trycss_cursor
export const Cursor = {
  Default: "default",
  Grab: "grab",
  Crosshair: "crosshair",
  RotateNE: `url('data:image/svg+xml;utf8,${rotateCursor(-90)}'), auto`,
  RotateNW: `url('data:image/svg+xml;utf8,${rotateCursor(180)}'), auto`,
  RotateSE: `url('data:image/svg+xml;utf8,${rotateCursor(0)}'), auto`,
  RotateSW: `url('data:image/svg+xml;utf8,${rotateCursor(90)}'), auto`,

  ResizeSE: "se-resize",
  ResizeSW: "sw-resize",
  ResizeNE: "ne-resize",
  ResizeNW: "nw-resize",
  ResizeE: "e-resize",
  ResizeN: "n-resize",
  ResizeW: "w-resize",
  ResizeS: "s-resize",
  scaleSE: "se-resize",
};

export const SWEEP = {
  ANTICLOCKWISE: "0",
  CLOCKWISE: "1",
};

export const DEFTYPES = {
  LINEARGRADIENT: "linear-gradient",
  RADIALGRADIENT: "radial-gradient",
  IMPORTURL: "import",
  CLIPPATH: "clippath",
  MARKER: "marker",
  PATTERN: "pattern",
};
