import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import TemplateSearchFullpage from "./TemplateSearchFullpage";
import { BottomBar } from "./BottomBar";
import { Designs } from "./Designs";
import { EditorNavBar } from "./EditorNavBar";
import Editor from "./Editor";
import { useSVG } from "./SVGContext";
import { Terms } from "./terms/terms";
import { Privacy } from "./terms/privacy";
import { Contact } from "./terms/contact";
import { Banner } from "./Banner";
import SelectedTemplatesList from "./SelectedTemplatesList";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { Switch } from "@mui/material";
import { WordpressToken } from "./WordpressToken";
import ContentCalendar from "./views/content_calendar/ContentCalendar";

export function MyRoutes() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Login />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />

        <Route
          path="/"
          element={
            <>
              <EditorNavBar />
              <SelectedTemplatesList />
            </>
          }
        />
        <Route path="/templates/:cat" element={<TemplateSearchFullpage />} />
        <Route path="/templates" element={<TemplateSearchFullpage />} />
        {/* <Route path="/ai" element={<TemplateSearchAI />} /> */}
        {/* <Route path="/bookmarks" element={<BookmarkedTemplates />} /> */}

        <Route
          path="/wordpress-token"
          element={
            <>
              <EditorNavBar />
              <WordpressToken />
            </>
          }
        />
        <Route
          path="/designs"
          element={
            <>
              <EditorNavBar />
              <Designs />
            </>
          }
        />

        <Route
          path="/design/:design_id"
          element={
            <>
              <EditorNavBar />
              <Editor />
            </>
          }
        />
        <Route
          path="/block/:block_id"
          element={
            <>
              <EditorNavBar />
              <Editor />
            </>
          }
        />

        <Route
          path="/template/:template_id"
          element={
            <>
              <EditorNavBar />
              <Editor />
            </>
          }
        />

        <Route
          path="/calendar"
          element={
            <>
            <ContentCalendar />
            </>
          }
        />

        {["terms", "privacy", "contact"].map((p, i) => (
          <Route
            key={i}
            path={"/" + p}
            element={
              <div>
                <Banner />
                <div style={{ padding: "0 20px 20px 20px", marginBottom: 30 }}>
                  {p === "terms" && <Terms />}
                  {p === "privacy" && <Privacy />}
                  {p === "contact" && <Contact />}
                </div>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                  }}
                >
                  <BottomBar />
                </div>
              </div>
            }
          />
        ))}
      </Routes>
    </Router>
  );
}
