// @ts-nocheck

import { ROOT_GROUP_ID } from "../values/constants";

function toSelectedObjects(selectedIds, items, groups) {
  let selectedGroupOrItems = [];
  for (let i of selectedIds) {
    if (items[i].group == ROOT_GROUP_ID) {
      // this means this item belongs to mainGroup
      selectedGroupOrItems.push(items[i]);
    } else {
      // this means this item belongs to a group other than mainGroup
      selectedGroupOrItems.push(groups[items[i].group]);
    }
  }
  return Array.from(new Set(selectedGroupOrItems));
}

export const arrange = (z, type) => {
  const { selectedIds, items, groups, defs, update, width, height } = z;
  
  const selectedObjects = toSelectedObjects(selectedIds, items, groups);
  if (selectedObjects.length == 0) return;

  if (["bringtofront", "bringforward", "sendbackward", "sendtoback"].includes(type)) {
    const selectedObject = selectedObjects[0];
    const group = groups[selectedObject.group];
    const idx = group.children.indexOf(selectedObject.id);
    group.children = group.children.filter((id) => id != selectedObject.id);
    
    if (type == "bringtofront") {
      // all the way to the front of this group
      group.children.push(selectedObject.id);
    }
    if (type == "bringforward") {
      // one step forward
      group.children.splice(idx + 1, 0, selectedObject.id);
    }
    if (type == "sendbackward") {
      // one step backward
      group.children.splice(idx - 1, 0, selectedObject.id);
    }
    if (type == "sendtoback") {
      // all the way to the back
      group.children.unshift(selectedObject.id);
    }
  }

  // align to page
  if (type == "left") {
    for (let i of selectedIds) {
      items[i].x = 0;
    }
  }
  if (type == "center") {
    for (let i of selectedIds) {
      items[i].x = width / 2 - items[i].width / 2;
    }
  }

  if (type == "right") {
    for (let i of selectedIds) {
      items[i].x = width - items[i].width;
    }
  }
  if (type == "top") {
    for (let i of selectedIds) {
      items[i].y = 0;
    }
  }
  if (type == "middle") {
    for (let i of selectedIds) {
      items[i].y = height / 2 - items[i].height / 2;
    }
  }
  if (type == "bottom") {
    for (let i of selectedIds) {
      items[i].y = height - items[i].height;
    }
  }

  // to each other
  if (type.startsWith("eachother-")) {
    type  = type.replace("eachother-", "");
    for (let i = 1; i < selectedIds.length; i++) {
      if (type == "left") {
        items[selectedIds[i]].x = items[selectedIds[i - 1]].x;
      } else if (type == "right") {
        items[selectedIds[i]].x = items[selectedIds[i - 1]].x + items[selectedIds[i - 1]].width - items[selectedIds[i]].width;
      } else if (type == "center") {
        items[selectedIds[i]].x = items[selectedIds[i - 1]].x + items[selectedIds[i - 1]].width / 2 - items[selectedIds[i]].width / 2;
      } else if (type == "top") {
        items[selectedIds[i]].y = items[selectedIds[i - 1]].y;
      } else if (type == "bottom") {
        items[selectedIds[i]].y = items[selectedIds[i - 1]].y + items[selectedIds[i - 1]].height - items[selectedIds[i]].height;
      } else if (type == "middle") {
        items[selectedIds[i]].y = items[selectedIds[i - 1]].y + items[selectedIds[i - 1]].height / 2 - items[selectedIds[i]].height / 2;
      }
    }    
  }
  update({
    items: { ...items }
  });
};
