// @ts-nocheck
import { Box, Button, ClickAwayListener, IconButton, Menu, MenuItem, Popper, Slider, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import { TopbarCommons } from "./TopbarCommons";
import MyButton from "./MyButton";

export const TopbarIcon = ({ item }) => {

  return (
    <>
      <Replace item={item} />
      <FillColor />
      <FillColor isStroke={true} />
      <StrokeWidth item={item} />
      <Dash item={item} />
    </>
  );
};
function FillColor({ isStroke }) {
  const { items, groups, defs, selectedIds, update } = useSVG();
  const [clr, setClr] = useState("black");

  useEffect(() => {
    if (selectedIds.length == 0) return;
    let item = items[selectedIds[0]];
    setClr(isStroke ? item.stroke : item.fill);
  }, [selectedIds]);

  return (
    <ColorSquare
      isStroke={isStroke}
      color={clr}
      onChange={(color) => {
        setClr(color.hex);
        for (let id of selectedIds) {
          if (isStroke) items[id].stroke = color.hex;
          else items[id].fill = color.hex;
        }
        update({ items });
      }}
    />
  );
}

function StrokeWidth({ item }) {
  const { items, groups, defs, selectedIds, update } = useSVG();
  const [anchorEl, setAnchor] = useState(null);
  return (
    <>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        <ShowChartIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem>
          <Slider
            style={{ width: "100px" }}
            value={item.strokeWidth}
            onChange={(e) => {
              item.strokeWidth = e.target.value;
              items[item.id] = item;
              update({ items, groups, defs, selectedIds });
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

const popperStyle = {
  backgroundColor: "white",
  border: "solid 1x gray",
};
const options = [[], [5], [2], [5, 10]];

function Line({ dashArray }) {
  return (
    <svg width="40px" height="30" className="dasharray-svg" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <line x1="0" y1="5" x2="100" y2="5" stroke="black" stroke-width="2" stroke-dasharray={dashArray.join(", ")} />
    </svg>
  );
}
function DashPopper({ anchorEl, onClose, onClick }) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} style={popperStyle}>
        <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
          {options.map((option, index) => {
            return (
              <div key={index} onClick={() => onClick(index)}>
                <Line dashArray={option} />
              </div>
            );
          })}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}
export function Dash({ item }) {
  const { items, groups, defs, selectedIds, update } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);

  function onClick(index) {
    // this is to make the dash-change obvious to the user
    item.strokeWidth = item.strokeWidth || 2;
    item.stroke = item.stroke || "black";

    items[item.id].strokeDasharray = options[index].join(", ");
    update({ items, groups, defs, selectedIds });

    setAnchorEl(null);
  }

  const currentValue = item.strokeDasharray?.split(", ") || [];
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "start", alignItems: "start" }}>
        <IconButton
          ref={ref}
          type="button"
          value="Dashed Line"
          onClick={() => {
            setAnchorEl(anchorEl ? null : ref.current);
          }}
        >
          <Line dashArray={currentValue} />
        </IconButton>
        {/* <input
          type="text"
          style={{ width: "100%" }}
          value={currentValue.join(" ")}
          onChange={(e) => {
            items[item.id].strokeDasharray = e.target.value;
            update({ items, groups, defs, selectedIds });
          }}
        /> */}
      </div>
      {anchorEl && <DashPopper anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onClick={onClick} />}
    </div>
  );
}
function Replace({ item }) {
  const { replacingItemId, setReplacingItemId, setLeftPanel } = useSVG();
  return (
    <MyButton
      isPressed={replacingItemId}
      size="small"
      variant="outlined"
      onClick={() => {
        setReplacingItemId(item.id);
        setLeftPanel("images");
      }}
    >
      Replace
    </MyButton>
  );
}
