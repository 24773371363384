import { DEFTYPES } from "../values/enums";

export function ShapeView({ item }) {  
  // can delete item.type == "background" soon
  if (item.type == "rectangle" || item.type == "background") {
    return <rect {...item} />;
  }
  if (item.type == "circle") {
    item.cx = item.x + item.width / 2;
    item.cy = item.y + item.height / 2;
    item.r = item.width / 2;
    return <circle {...item} />;
  }
  if (item.type == "ellipse") {
    item.cx = item.x + item.width / 2;
    item.cy = item.y + item.height / 2;
    item.rx = item.width / 2;
    item.ry = item.height / 2;
    return <ellipse {...item} />;
  }
  if (item.type == "triangle") {
    const points = `${item.x + item.width / 2},${item.y} ${item.x + item.width},${item.y + item.height} ${item.x},${item.y + item.height}`;
    item.points = points;
    return <polygon {...item} />;
  }
  if (item.type == "star") {
    let points = [];
    for (let i = 0; i < 5; i++) {
      const r = i % 2 == 0 ? item.width / 2 : item.width / 4;
      const angle = (Math.PI / 5) * i;
      points.push(`${item.x + item.width / 2 + r * Math.sin(angle)},${item.y + item.height / 2 + r * Math.cos(angle)}`);
    }
    item.points = points.join(" ");
    return <polygon {...item} />;
  }
  if (item.type == "pentagon") {
    let points = [];
    for (let i = 0; i < 5; i++) {
      const angle = (Math.PI / 5) * i;
      points.push(`${item.x + item.width / 2 + (item.width / 2) * Math.sin(angle)},${item.y + item.height / 2 + (item.width / 2) * Math.cos(angle)}`);
    }
    item.points = points.join(" ");
    return <polygon {...item} />;
  }
  if (item.type == "hexagon") {
    let points = [];
    for (let i = 0; i < 6; i++) {
      const angle = (Math.PI / 3) * i;
      points.push(`${item.x + item.width / 2 + (item.width / 2) * Math.sin(angle)},${item.y + item.height / 2 + (item.width / 2) * Math.cos(angle)}`);
    }
    item.points = points.join(" ");
    return <polygon {...item} />;
  }
  if (item.type == "semi-circle") {
    const start = { x: item.x, y: item.y };
    const rx = item.width / 2,
      ry = item.height / 2;
    const end = { x: item.x + item.width, y: item.y };
    const d = `M ${start.x},${start.y} A ${rx},${ry} 0 0,1 ${end.x},${end.y} Z`;
    item.d = d;
    return <path {...item} />;
  }
  if (item.type == "quarter-circle") {
    const d = `M ${item.x + item.width},${item.y} A ${item.width},${item.height} 0 0,1 ${item.x},${item.y + item.height} L ${item.x},${item.y} Z`;
    item.d = d;
    return <path {...item} />;
  }
  if (item.type == "straight") {
    const { xPx: x1, yPx: y1 } = item.startPos;
    const { xPx: x2, yPx: y2 } = item.endPos;

    // if (item.x != undefined || item.y != undefined) {
    //   item.x = item.x || 0;
    //   item.y = item.y || 0;
    //   item.transform = "translate(" + +item.x + " " + item.y + ")";
    //   delete item.x;
    //   delete item.y;
    // }
    return (
      <g {...item} transform={"translate(" + item.x + " " + item.y + ")"}>
        <line x1={x1} y1={y1} x2={x2} y2={y2} marker-end="url(#end-arrow)" marker-start="url(#start-arrow)" />
      </g>
    );
  }
  if (item.type == "block-arrow-indent") {
    return (
      <path
        {...item} transform={"translate(" + item.x + " " + item.y + ")"}
        d={generateBlockArrowIndentFillPath(item)}
      />
    );
  }
}

const generateBlockArrowIndentFillPath = (item) => {
  const dt = 31;
  const ot = item.width;
  const at = item.height;
  const st = item.strokeWidth;
  const ft = Math.atan(dt / (at / 2));
  return `M ${st / Math.tan(Math.PI / 4 - ft / 2)}, ${st}
  L ${ot - at / 2 * Math.tan(ft) - st / Math.tan(Math.PI / 4 + ft / 2)}, ${st}
  L ${ot - st / Math.sin(Math.PI / 2 - ft)}, ${at / 2}
  L ${ot - at / 2 * Math.tan(ft) - st / Math.tan(Math.PI / 4 + ft / 2)}, ${at - st}
  L ${st / Math.tan(Math.PI / 4 - ft / 2)}, ${at - st}
  L ${at / 2 * Math.tan(ft) + st / Math.sin(Math.PI / 2 - ft)}, ${at / 2}
  Z`

}
