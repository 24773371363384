import { Button, Dialog, DialogActions, IconButton, Slider, createSvgIcon } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSVG } from "./SVGContext";
import { frames } from "./topbar/TopbarImage";
import { rand } from "./utils";
import { getCoordsInSvg } from "./utils/utils";

export function CropDialog({open, setOpen}) {
    const { items, groups, defs, selectedIds, update, addImage } = useSVG();

    const [item, setItem] = useState(null);
    const [presetFrames, setPresetFrames] = useState([]);
    const [presetIdPrefix, setPresetIdPrefix] = useState("preset-frame-" + rand());

    useEffect(() => {
        const it = items[selectedIds[0]]
        if (!it?.imageRect) {
            setItem(null);
            return;
        }
        setItem(it);
        setImageRect(it.imageRect);
        const presetFrames = [{
            tag: "rect",
            x: 0.25,
            y: 0.25,
            width: 0.25,
            height: 0.25 * it.imageRect.width / it.imageRect.height,
        }, {
            tag: "ellipse",
            cx: 0.5,
            cy: 0.5,
            rx: 0.25,
            ry: 0.25 * it.imageRect.width / it.imageRect.height,
        }];
        setPresetFrames(presetFrames);
        if (it.customFrame) {
            setCustomFrame(it.customFrame);
        }
        
    }, [selectedIds]);


    let [mouseDown, setMouseDownInt] = useState(null);
    let [mouseMove, setMouseMoveInt] = useState(null);
    const [imageZoom, setImageZoom] = useState(100);
    const [customFrame, setCustomFrame] = useState(null);
    let [crop, setCrop] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
    const [imageRect, setImageRect] = useState({ width: 0, height: 0 });
    const mySvgRef = useRef(null);

    let [cropDragging, setCropDraggingInternal] = useState(false);
    let [cropMouseMove, setCropMouseMove] = useState(null);
    let [cropPoint, setCropPoint] = useState({x:1, y:1});

    if (!item || !item.imageRect) return null;

    const setMouseDown = (xy) => {
        mouseDown = xy;
        setMouseDownInt(xy);
    };
    const setMouseMove = (xy) => {
        mouseMove = xy;
        setMouseMoveInt(xy);
    };

    const getXY = (e) => {
        let x,y;
        if (e.touches && e.touches.length == 1 && e.touches[0]) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        } else {
            x = e.clientX;
            y = e.clientY;
        }
        return getCoordsInSvg(mySvgRef, { x, y });
    }

    const onMouseDown = (e) => {
        const xy = getXY(e);
        setMouseDown(xy);
        setMouseMove(xy);
        e.preventDefault();
        e.stopPropagation();
    };

    const onMouseMove = (e) => {        
        if (cropDragging == "scaling") {
            return onCropMouseMove(e);
        }
        const xy = getXY(e);
        if (mouseDown) {
            const dx = mouseMove.x - xy.x;
            const dy = mouseMove.y - xy.y;
            
            let newCrop = {
                top: +crop?.top + dy,
                right: +crop?.right + dx,
                bottom: +crop?.bottom + dy,
                left: +crop?.left + dx,
            };
            // const box = imageRef.current?.getBoundingClientRect();
            // newCrop.top = Math.min(Math.max(0, newCrop.top), box.width - item.imageRect.width);
            // newCrop.left = Math.min(Math.max(0, newCrop.left), box.height - item.imageRect.height);
            
            crop = newCrop;
            setCrop(newCrop);

            setMouseMove(xy);
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onMouseUp = (e) => {
        if (cropDragging == "scaling") {
            return onCropMouseUp(e);
        }

        setMouseDown(null);
        setMouseMove(null);
        e.preventDefault();
        e.stopPropagation();
    };

    function zoom(d) {
        const top = +crop?.top - d;
        const left = +crop?.left + d;
        const right = +crop?.right + d;
        const bottom = +crop?.bottom + d;
        let newCrop = {
          top,
          right,
          bottom,
          left,
        };
        imageRect.width = +imageRect.width + 2 * d;
        imageRect.height = +imageRect.height + 2 * d;

        setCrop(newCrop);
        setImageRect(imageRect);
    }
    

    const h = 500;
    const w = item.imageRect.width * h / item.imageRect.height;


    /**************** scaling the crop box ********/
    function setCropDragging(d) {
        cropDragging = d;
        setCropDraggingInternal(d);
    }
    function onCropEdgeMouseDown(px, py) {
        cropPoint = { x: px, y: py };
        setCropPoint(cropPoint);
        setCropDragging("scaling");
    }
    function onCropMouseMove(e) {
        if (!cropDragging) return;
        const xy = getXY(e);
        if (!cropMouseMove) {
            cropMouseMove = xy;
            setCropMouseMove(xy);
            return;
        }
        
        const dx = (xy.x - cropMouseMove?.x) / item.imageRect.width;
        const dy = (xy.y - cropMouseMove?.y) / item.imageRect.height;
        const f = customFrame;
        if (cropDragging == "scaling") {
            if (f.tag == "rect") {
                const ar = f.height / f.width;
                f.width += dx * cropPoint.x;
                f.height += dy * cropPoint.y;
                f.height = f.width * ar;
                if (cropPoint.x == -1) {
                    f.x += dx;
                }
                if (cropPoint.y == -1) {
                    f.y += dy;
                }
                // f.width = Math.max(0, 1);
                // f.height = Math.max(0, 1);
                // f.x = Math.min(Math.max(0, f.x), 1);
                // f.y = Math.min(Math.max(0, f.y), 1);
            } else if (f.tag == "ellipse") {
                const ar = f.ry / f.rx;
                f.rx += dx * cropPoint.x;
                f.ry = f.rx * ar;
            }
        } else {
            console.log("dragging frame", f.x, customFrame)

            if (f.tag == "rect") {
                f.x += dx * cropPoint.x;
                f.y += dy * cropPoint.y;

                // f.x = Math.min(Math.max(0, f.x), 1);
                // f.y = Math.min(Math.max(0, f.y), 1);
            } else if (f.tag == "ellipse") {
                f.cx += dx * cropPoint.x;
                f.cy += dy * cropPoint.y;

                // f.cx = Math.min(Math.max(0, f.cx), 1);
                // f.cy = Math.min(Math.max(0, f.cy), 1);
            } 
        }
        setCustomFrame({...f});

        cropMouseMove = xy;
        setCropMouseMove(xy);
    }
    function onCropMouseUp(e) {
        setCropDragging(false);
        setCropMouseMove(null);
    }

    function finalize() {
        // take crop, zoom, and frame
        const f = customFrame;
        let innerHTML = '';
        if (f.tag == "rect") {
            innerHTML = `<rect x="${f.x}" y="${f.y}" width="${f.width}" height="${f.height}"/>`;
        } else if (f.tag == "ellipse") {
            innerHTML = `<ellipse cx="${f.cx}" cy="${f.cy}" rx="${f.rx}" ry="${f.ry}"/>`;
        }
        item.crop = crop;
        item.customFrame = {
            ...customFrame,
            innerHTML,
            clipPathUnits: "objectBoundingBox",
        }
        items[item.id] = item;
        update({items});

        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={() => {
            setOpen(false);
        }} style={{
        }}>
            <div style={{
                background: "#ddd",
                marginBottom: 20,
                padding: 10,
                textAlign: "center"
            }}>
                {presetFrames.map((frame, i) => (
                    <IconButton 
                        style={{
                            background: frame.name == item.frame ? "#ccc" : "",
                            margin: 5
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setCustomFrame({
                                index: i,
                                id: presetIdPrefix + i,
                                tag: frame.tag,
                                
                                x: frame.x,
                                y: frame.y,
                                width: frame.width,
                                height: frame.height,
                                cx: frame.cx,
                                cy: frame.cy,
                                rx: frame.rx,
                                ry: frame.ry,

                            });
                        }}
                        >
                        {frame.tag == "rect" && <Rect />}
                        {frame.tag == "ellipse" && <Circle />}
                    </IconButton>
                ))}
            </div> 
            <svg ref={mySvgRef} width="500" height="500" 

                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onTouchMove={onMouseMove}
                onTouchMoveCapture={onMouseMove}
                onTouchEnd={onMouseUp}
                >
                {customFrame && (
                    <defs>
                        <clipPath id={customFrame.id} clipPathUnits={"objectBoundingBox"}>
                            {customFrame.tag == "rect" && (
                                <rect x={customFrame.x} y={customFrame.y} width={customFrame.width} height={customFrame.height} fill="none" stroke="black" transform={customFrame?.transform} transformOrigin="center" />
                            )}
                            {customFrame.tag == "ellipse" && (
                                <ellipse cx={customFrame.cx} cy={customFrame.cy} rx={customFrame.rx} ry={customFrame.ry} fill="none" stroke="black" transform={customFrame?.transform} transformOrigin="center" />
                            )}
                        </clipPath>
                    </defs>
                )}
                <image 
                    style={{
                        top: -crop?.top,
                        left: -crop?.left,  
                        opacity: 0.2,
                    }}
                    x={-crop?.left}
                    y={-crop?.top}
                    height={imageRect.height}
                    width={imageRect.width}
                    draggable="false"
                    href={item.url}
                    onMouseDown={onMouseDown}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUp}
                    onTouchStart={onMouseDown}
                    onTouchMove={onMouseMove}
                    onTouchMoveCapture={onMouseMove}
                    onTouchEnd={onMouseUp}
                />
                <image 
                    style={{
                        top: -crop?.top,
                        left: -crop?.left,  
                        opacity: 1,
                        clipPath: customFrame?.id ? `url(#${customFrame?.id})` : ""                            
                    }}
                    x={-crop?.left}
                    y={-crop?.top}
                    height={imageRect.height}
                    width={imageRect.width}
                    draggable="false"
                    href={item.url}
                    onMouseDown={onMouseDown}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUp}
                    onTouchStart={onMouseDown}
                    onTouchMove={onMouseMove}
                    onTouchMoveCapture={onMouseMove}
                    onTouchEnd={onMouseUp}
                />

                <ResizableFrame item={item} crop={crop} customFrame={customFrame} onCropEdgeMouseDown={onCropEdgeMouseDown} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp} />

            </svg>
            <div
                style={{
                    margin: 20,
                    display: "flex",
                    alignItems: "space-around",
                    justifyContent: "center",
                    flexDirection: "row",
                    gap: 10
                }}
                >
                <Slider
                    min={50}
                    max={200}
                    step={5}
                    value={imageZoom}
                    onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const diff = imageZoom - e.target.value;
                        setImageZoom(e.target.value);
                        zoom(diff);
                    }}
                    style={{
                        width: "100%",
                    }}
                />
            </div>
            <DialogActions style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "center"
            }}>
                <Button style={{
                    textTransform: "none"
                }} 
                onClick={() => {setOpen(false)}} >Cancel</Button>
                <Button style={{
                    textTransform: "none",
                }} 
                variant="contained" onClick={finalize} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

const stroke = "black";
const strokeWidth = 1;
const Circle = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
    <circle cx="12" cy="12" r="10" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
</svg>,
"Circle",
);
const Rect = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
    <rect x="4" y="6" width="18" height="12" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
</svg>,
"Rect",
);
const Square = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
    <rect x="4" y="4" width="16" height="16" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
</svg>,
"Square",
);
export function getFramedImageBoundingBox({crop, customFrame, item}) {
    if (!crop) {
        crop = item.crop;
        customFrame = item.customFrame;
    }
    const f = customFrame;
    if (f.tag == "rect") {
        return {
            x: -crop?.left + f.x * item.imageRect.width,
            y: -crop?.top + f.y * item.imageRect.height,
            width: f.width * item.imageRect.width,
            height: f.height * item.imageRect.height
        };
    } else if (f.tag == "ellipse") {
        return {
            x: -crop.left + (f.cx - f.rx) * item.imageRect.width,
            y: -crop.top + (f.cy - f.ry) * item.imageRect.height,
            width: f.rx * 2 * item.imageRect.width,
            height: f.ry * 2 * item.imageRect.height
        }
    }
}
function ResizableFrame({item, crop, customFrame, onCropEdgeMouseDown, onMouseDown, onMouseUp, onMouseMove}) {
    const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });

    useEffect(() => {
        if (!customFrame) return;
        setRect(getFramedImageBoundingBox({crop, customFrame, item}));
    }, [customFrame, crop]);

    if (!customFrame) return null;

    const r = 7;
    return <>
        <rect x={rect.x} y={rect.y} width={rect.width} height={rect.height} fill="transparent" stroke="black" strokeWidth={1} 
        
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchMove={onMouseMove}
        onTouchMoveCapture={onMouseMove}
        onTouchEnd={onMouseUp}
        />
        
        <circle cx={rect.x + rect.width} cy={rect.y} r={r} fill="black" cursor="nesw-resize" onMouseDown={() => onCropEdgeMouseDown(1, -1)}/>
        <circle cx={rect.x + rect.width} cy={rect.y + rect.height} r={r} fill="black" cursor="nwse-resize" onMouseDown={() => onCropEdgeMouseDown(1, 1)}/>
        <circle cx={rect.x} cy={rect.y + rect.height} r={r} fill="black" cursor="nesw-resize" onMouseDown={() => onCropEdgeMouseDown(-1, 1)}/>
        <circle cx={rect.x} cy={rect.y} r={r} fill="black" cursor="nwse-resize" onMouseDown={() => onCropEdgeMouseDown(-1, -1)}/>

    </>;
}