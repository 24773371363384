import { Button, Checkbox, Divider, MenuItem, Select, Switch, TextField } from "@mui/material";
import { req, updateDesignServer } from "../utils/ServerUtils";
import { useSVG } from "../SVGContext";
import { randomColor } from "../LayoutFinder";
import { useEffect, useState } from "react";
import { attachBoxes } from "../EditorNavBar";
import { Pages } from "../Pages";

export function LayoutMarkingPanel() {
  const [gap, setGap] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [paddingRight, setPaddingRight] = useState(0);

  const [finalizeText, setFinalizeText] = useState("Finalize");
  const [finalizeBg, setFinalizeBg] = useState("purple");
  const [showSpec, setShowSpec] = useState(true);
  const [iconStyle, setIconStyle] = useState("filled");
  
  const [cols, setCols] = useState(1);
  // const [bid, setBid] = useState(0);
  const { design, items, selectedIds, update, getBox, setDesign, selectedPage} = useSVG();
  let { specMap, setSpecMap } = useSVG();

  useEffect(() => {
    setCols(design.pages[selectedPage].spec?.cols || 1);
    setGap(design.pages[selectedPage].spec?.gap || 0);
    setPaddingTop(design.pages[selectedPage].spec?.padding?.top || 0);
    setPaddingBottom(design.pages[selectedPage].spec?.padding?.bottom || 0);
    setPaddingLeft(design.pages[selectedPage].spec?.padding?.left || 0);
    setPaddingRight(design.pages[selectedPage].spec?.padding?.right || 0);

  }, [design, selectedPage]);

  useEffect(() => {
    let shouldAttachBoxes = false;
    for (let id in items) {
      if (!items[id].box) {
        shouldAttachBoxes = true;
      }
    }
    if (shouldAttachBoxes) {
      console.log("Attaching boxes & saving");
      attachBoxes(design, getBox);
      updateDesignServer(design)
          .then((res) => {
            setDesign({ ...design, version: res.version });
          });
    }
  }, [])

  
  useEffect(() => {
    showHideSpec();
  }, [showSpec]);

  function showHideSpec() { 
    if (!showSpec) {
      // clear view
      specMap = {};
      setSpecMap({...specMap});
      return
    }

    const spec = design.pages[selectedPage].spec || {};
    specMap = {};
    for (let bid of ["header", "footer"]) if (spec[bid]) {
      const color = randomColor();
      for (let f in spec[bid].id_spec) {
        const id = spec[bid].id_spec[f];
        specMap[id] = {
          bid,
          type: f,
          color,
        };
      }
    }
    if (spec.blocks) {
      for (let bid = 0; bid < spec.blocks.length; bid++) {
        const block = spec.blocks[bid];
        const color = randomColor();
        for (let f in block.id_spec) {
          const id = block.id_spec[f];
          specMap[id] = {
            bid,
            type: f,
            color
          };
        }
      }    
    }
    setSpecMap(specMap);
  }

  function poll(taskId) {
    req("/poll", "POST", JSON.stringify({ task_id: taskId })).then((res) => {
      if (res.status == "pending") {
        setTimeout(() => poll(taskId), 10000);
      } else if (res.status == "completed") {
        console.log("Completed ", res);
        window.open(res.result?.url, "_blank");
      } else {
        console.error("Error: ", res);
      }
    }).catch(showError);

  }

  function showSuccess(res) {
    console.log("Finalized ", res);
    setFinalizeText("Finalized!");
    setFinalizeBg("limegreen");
    setTimeout(() => {
      setFinalizeText("Finalize");
      setFinalizeBg("purple");
    }, 5000);
  }
  function showError(err) {
    console.error("Error: ", err);
    setFinalizeText("Error!");
    setFinalizeBg("red");

    setTimeout(() => {
      setFinalizeText("Finalize");
      setFinalizeBg("purple");
    }, 5000);
  }

  function finalize() {
    setFinalizeText("Finalizing...");
    setFinalizeBg("gray");
    const spec = design.pages[selectedPage].spec || {};

    // if any spec (for header/footer/etc) is unset, set a default spec
    for (let key of ["header", "footer"]) {
      if (!spec[key]?.rendering_order) {
        spec[key] = {
          rendering_order: [],
          order: [],
          id_spec: {}
        };
      }
    }
    spec.cols = parseInt(cols);
    spec.gap = parseInt(gap);
    spec.padding = {
      top: parseInt(paddingTop),
      bottom: parseInt(paddingBottom),
      left: parseInt(paddingLeft),
      right: parseInt(paddingRight),
    }

    deduceContainers();

    specMap = null; // clear view
    design.pages[selectedPage].spec = spec;
    setDesign(design);
    req(
      "/templates",
      "PUT",
      JSON.stringify(design),
    ).then(res => {
      design.version = res.version;
      setDesign(design);

      console.log("Finalized ", res);
      console.log("Generating sample now...");

      return req(
        "/generate-sample",
        "POST",
        JSON.stringify({
          subject: design.title,
          template_id: "" + design.id,
          icon_style: iconStyle
        }),
      )
    }).then((res) => {
      if (res.task_id) poll(res.task_id);
      showSuccess(res);
    }).catch(showError);
  }

  function deduceBlock(bid) {
    const params = {
      template_id: "" + design.id,
      block_id: bid,
      block_item_ids: selectedIds,
    };

    req("/deduce_block_spec", "POST", JSON.stringify(params)).then((res) => {
      const spec = design.pages[selectedPage].spec || {};
      if (["header", "footer"].includes(bid)) {
        spec[bid] = res.result;
      } else {
        spec.blocks = spec.blocks || [];
        spec.blocks[bid] = res.result;
      }
      design.pages[selectedPage].spec = spec;
      setDesign(design);

      specMap = specMap || {};
      for (let f in res.result.id_spec) {
        specMap[res.result.id_spec[f]] = {
          bid: bid,
          type: f,
          color: randomColor(),
        };
      }
      setSpecMap(specMap);
    });
  }

  function deduceContainers() {
    // deducing block containers: find min x,y of 0th block
    let x = Infinity,
      y = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    
    function deduceForBlock(block) {
      for (let id of block.rendering_order) {
        const item = items[id];
        x = Math.min(x, item["box"]["x"]);
        y = Math.min(y, item["box"]["y"]);
        maxX = Math.max(maxX, item["box"]["x"] + item["box"]["boxWidth"]);
        maxY = Math.max(maxY, item["box"]["y"] + item["box"]["boxHeight"]);
      }
    }
    const spec = design.pages[selectedPage].spec || {};
    deduceForBlock(spec["header"]);
    spec.blocks.length > 0 && deduceForBlock(spec["blocks"][0]);
    deduceForBlock(spec["footer"]);
    
    spec["gap"] = parseInt(gap);
    spec["cols"] = cols;
    spec["containers"] = [
      {
        x,
        y,
        width: maxX - x,
        height: maxY - y,
      },
    ];
    design.pages[selectedPage].spec = spec;
    setDesign(design);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "start",
        gap: 20,
        padding: 20,
      }}
    >
      {/* <Pages /> */}

      {selectedIds.length == 1 && <ItemMarkingPanel />}
      
      <ToggleButton checked={showSpec} setChecked={setShowSpec} />
      <TextField label="Columns" value={cols} onChange={(e) => setCols(e.target.value)} />
      <TextField label="Gap Between Blocks" value={gap} onChange={(e) => setGap(e.target.value)} />
      <div style={{
        marginLeft: 8,
        marginTop: 0,
        marginBottom: 0
      }}>Padding:</div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 10,
      }}>
        <TextField label="Top" value={paddingTop} onChange={(e) => setPaddingTop(e.target.value)} />
        <TextField label="Bottom" value={paddingBottom} onChange={(e) => setPaddingBottom(e.target.value)} />
        <TextField label="Left" value={paddingLeft} onChange={(e) => setPaddingLeft(e.target.value)} />
        <TextField label="Right" value={paddingRight} onChange={(e) => setPaddingRight(e.target.value)} />
      </div>
      

      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: 10
      }}>
        {["header", 0, 1, "footer"].map((block, index) => (
          <Button
            key={index}
            onClick={() => deduceBlock(block)}
            variant="contained"
            fullWidth
            style={{
              textTransform: "none",
            }}
          >
          Set as {block == 0 || block == 1  ? ("block ") : " "}{block}
        </Button>
        ))}

        {/* <Select value={bid} onChange={(e) => setBid(e.target.value)} fullWidth>
          <MenuItem value={"header"}>Header</MenuItem>
          <MenuItem value={"0"}>Block 0</MenuItem>
          <MenuItem value={"1"}>Block 1</MenuItem>
          <MenuItem value={"footer"}>Footer</MenuItem>
        </Select>
        <Button
          onClick={deduceBlock}
          variant="contained"
          fullWidth
          style={{
            textTransform: "none",
          }}
        >
          Deduce Spec
        </Button> */}
      </div>

      <div>
        {/* for more: https://www.reddit.com/r/dalle2/comments/ulxg0x/list_of_words_that_dalle_associates_with_high/
        https://www.reddit.com/r/dalle2/comments/udm2ww/unexplored_stylesmedia/
        this guide: https://docs.google.com/document/d/11WlzjBT0xRpQhP9tFMtxzd0q6ANIdHPUBkMV-YB043U/edit?tab=t.0#heading=h.8g22xmkqjtv7
        https://dalle2.gallery/#search */}
        <Select value={iconStyle} onChange={(e) => setIconStyle(e.target.value)} fullWidth>
          <MenuItem value={"minimalistic, cartoon-like vector art of "}>people</MenuItem>
          <MenuItem value={"muted colors, professional hand-drawn"}>hand-drawn</MenuItem>
          <MenuItem value={"cartoon-like, colorful, and vibrant"}>cartoon</MenuItem>
        </Select>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          style={{
            textTransform: "none",
            backgroundColor: finalizeBg,
            color: "white",
          }}
          onClick={finalize}
        >
          {finalizeText}
        </Button>
      </div>
      
    </div>
  );
}

const ToggleButton = ({checked, setChecked}) => {

  const handleChange = (event) => {
      setChecked(event.target.checked);
  };

  return <div style={{
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "start",
  }}>
    
    <Switch checked={checked} onChange={handleChange} sx={{ 
      '& .MuiSwitch-switchBase.Mui-checked': { color: checked ? 'limegreen' : '' }, 
      '& .MuiSwitch-track': { backgroundColor: checked ? 'limegreen' : '' } 
    }}/>
    Toggle Layout
  </div>;
};

function ItemMarkingPanel({}) {
  /* to add
  which item font size can be changed?
  */
  const {selectedIds, selectedPage, items, update, specMap} = useSVG();
  const [overflow, setOverflow] = useState(false);
  const [doNotSubstitute, setDoNotSubstitute] = useState(false);
  const [belongsTo, setBelongsTo] = useState(null);

  useEffect(() => {
    const s = specMap[selectedIds[0]];
    if (!s) return;
    if (s.bid != "header" && s.bid != "footer") {
      setBelongsTo("Block " + s.bid);
    } else {
      setBelongsTo(s.bid);
    }
  }, [specMap, selectedIds])

  if (selectedIds.length != 1 || !specMap[selectedIds[0]]) return null;
  
  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        border: "1px solid lightgray",
        borderRadius: 5,
        padding: 10,
        marginBottom: 40,
      }}>
        <div style={{
          textAlign: "center",
          fontWeight: "bold",
        }}>Item Details</div>


        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: 0,
          alignItems: "center",
          justifyContent: "start",
        }}>
          <Checkbox value={overflow} onChange={(e) => {
            setOverflow(e.target.checked);
            for (let id of selectedIds) {
              items[id]['generation-options'] = items[id]['overflow'] || {};
              items[id]['generation-options']['overflow'] = e.target.checked;
            }
            update({items})
          }}/>
          <span>Overflow</span>
        </div>

        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: 0,
          alignItems: "center",
          justifyContent: "start",
        }}>
          <Checkbox value={doNotSubstitute} onChange={(e) => {
            setDoNotSubstitute(e.target.checked);
            for (let id of selectedIds) {
              items[id]['generation-options'] = items[id]['generation-options'] || {};
              items[id]['generation-options']['do-not-substitute'] = e.target.checked;
            }
            update({items})
          }}/>
          <span>Do Not Substitute</span>
        </div>

        <div>
          <div>Type: {specMap[selectedIds[0]]?.type}</div>
          <div>Belongs To: {belongsTo}</div>
        </div>
      </div>
    </div>
  )
}