// @ts-nocheck
import { useState } from "react";
import { useSVG } from "./SVGContext";
import { copy, cut, paste } from "./utils/ClipboardUtils";
function noModifier(e) {
  return !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey;
}
function ShortcutHandler({ startFileUpload }) {
  const { update, setSelectedIds, setMultiSelectBox, undo, redo, saveAsSvgFile, zoomIn, zoomOut, history, deleteItems } = useSVG();

  let [isCtrlDown, setCtrlDown] = useState(false);

  const handleKeyDown = ({ event, items, groups, selectedIds, z }) => {
    // GOTCHA: when export's text field is being typed, if I don't do this check for App/Body here
    // the text field's onChange gets canceled here.
    // If onKeyDown, as it bubbles up, is canceled, then onChange of textfield won't work.
    const act = document.activeElement;
    if ((act?.getAttribute("class") == "App" || act?.getAttribute("class") == "Board" || act?.tagName == "BODY") && handleKeyDownInternal({ event, items, groups, selectedIds, z })) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleKeyDownInternal = ({ event, items, groups, defs, selectedIds, z }) => {
    // console.log("Shortcuthandler handleKeyDown: ", event.key, event.keyCode, event.ctrlKey, event.metaKey, event.shiftKey, event.altKey);
    if (event.key == "ArrowUp" || event.key == "ArrowDown" || event.key == "ArrowLeft" || event.key == "ArrowRight") {
      for (let id of selectedIds) {
        let item = items[id];
        item.x = item.x || 0;
        item.y = item.y || 0;
        if (event.key === "ArrowUp") {
          item.y -= 1;
        }
        if (event.key === "ArrowDown") {
          item.y += 1;
        }
        if (event.key === "ArrowLeft") {
          item.x -= 1;
        }
        if (event.key === "ArrowRight") {
          item.x += 1;
        }
        update({ items });
        return true;
      }
    }

    // undo
    if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      history.undo(items);
      return true;
    }
    // redo
    if ((event.ctrlKey || event.metaKey) && event.key === "y") {
      redo(items);
      return true;
    }

    // save file
    if ((event.ctrlKey || event.metaKey) && event.key === "s") {
      // todo: save
      return true;
    }

    // select all
    if ((event.ctrlKey || event.metaKey) && event.key === "a") {
      setSelectedIds(Object.keys(items));
      return true;
    }

    // zooming
    if ((event.ctrlKey || event.metaKey) && (event.key === "+" || event.key === "=")) {
      z.zoomIn();
      return true;
    }
    if ((event.ctrlKey || event.metaKey) && event.key === "-") {
      z.zoomOut();
      return true;
    }

    // Check if the Delete key was pressed
    if (event.key === "Delete" || event.key === "Backspace" || event.keyCode === 46) {
      deleteItems(selectedIds);
      return true;
    }

    // cut
    if ((event.ctrlKey || event.metaKey) && event.key === "x") {
      cut(z);
      return true;
    }

    // copy
    if ((event.ctrlKey || event.metaKey) && event.key === "c") {
      copy(z);
      return true;
    }

    // paste
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      paste(z);
      return true;
    }
    if (event.key === "Control") {
      isCtrlDown = true;
      setCtrlDown(isCtrlDown);
    }

    // show/hide layout marking panel
    if (event.ctrlKey && event.key === "b" && z.userInfo?.permissions.includes("designer")) {
      z.setLeftPanel(z.leftPanel ? null : "layout");
      return true;
    }

  };

  const handleKeyUp = () => {
    isCtrlDown = false;
    setCtrlDown(isCtrlDown);
  };
  return { handleKeyDown, handleKeyUp };
}
export default ShortcutHandler;
