import React, { useState, useEffect, useRef } from "react";
import { allGoogleFonts } from "./values/all-google-fonts";
import { Button, Menu, MenuItem, Select, TextField } from "@mui/material";
import { useSVG } from "./SVGContext";
import { updateAllSelectedTexts } from "./topbar/TopbarText";
import WebFont from "webfontloader";
import { updateFontFace } from "./utils/proseMirrorUtils";

const fonts = Object.keys(allGoogleFonts);

const LazyFonts = ({ }) => {
  const {selectedIds, items, update, editor, setEditorState, editorState, design, setDesign, selectedPage} = useSVG();

  const [allFonts, setAllFonts] = useState([]);
  const [displayedFonts, setDisplayedFonts] = useState([]); // Holds currently displayed palettes
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const containerRef = useRef(null);
  const rowsToShow = 30;

  const onFontSelect = (font) => {
    WebFont.load({
      google: {
        families: [font],
      },
    });

    design.pages[selectedPage].fonts.push(font);
    design.pages[selectedPage].fonts = Array.from(new Set(design.pages[selectedPage].fonts));
    setDesign({ ...design });

    if (selectedIds.length >= 1) {
      updateAllSelectedTexts(items, selectedIds, updateFontFace, font, update);
      return;
    }

    editor.chain().focus().setFontFamily(font).run();
    // Manually dispatch a transaction to trigger onUpdate
    editor.view.dispatch(editor.state.tr);

    setEditorState({
      ...editorState,
      font: font,
    });
  }

  useEffect(() => {
    setAllFonts(fonts);
  }, []);

  // Initially show the first 5 rows
  useEffect(() => {
    setDisplayedFonts([...allFonts.slice(0, rowsToShow)]);
  }, [allFonts]);

  // Load more fonts when the user scrolls to the bottom
  const loadMoreFonts = () => {
    if (loading) return;
    console.log("Loading more fonts...");
    setLoading(true);

    setTimeout(() => {
      const currentCount = displayedFonts.length;
      // Get the next 5 rows
      const nextFonts = allFonts.slice(currentCount, currentCount + rowsToShow);
      setDisplayedFonts((prev) => [...prev, ...nextFonts]);
      setLoading(false);
    }, 1000); // Simulate loading delay
  };


  useEffect(() => {
    const handleScroll = () => {
      // const bottom = containerRef.current.scrollHeight === containerRef.current.scrollTop + containerRef.current.clientHeight;
      const bottom = containerRef.current.scrollHeight - containerRef.current.scrollTop <= containerRef.current.clientHeight + 10; // Adding a small buffer
    
      if (bottom) {
        loadMoreFonts();
      }
    };

    const refCurrent = containerRef.current;
    if (!refCurrent) return;

    refCurrent.addEventListener("scroll", handleScroll);

    return () => {
      refCurrent.removeEventListener("scroll", handleScroll);
    };
  }, [displayedFonts, loading, anchorEl]);

  const [query, setQuery] = useState("");
  function handleQueryChange(e) {
    setQuery(e.target.value);
    const filteredFonts = fonts.filter((font) =>
      font.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDisplayedFonts(filteredFonts.slice(0, 100));
  }

  return (
    <>
      <TextField 
      placeholder="Search Fonts"
      style={{
        marginTop: 30,
        margin: 10
      }} value={query} onChange={handleQueryChange} />
      <div
        open={Boolean(anchorEl)}
        ref={containerRef}
        style={{
          width: 300,
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {displayedFonts.map((font, index) => (
          <MenuItem key={index} onClick={() => onFontSelect(font)}>            
            {font}
          </MenuItem>
        ))}
        {loading && (
          <MenuItem >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
              }}
            ></div>
          </MenuItem>
        )}
      </div>
    </>
  );
};

export default LazyFonts;
